import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Checkbox,
  Tooltip,
  Typography,
  makeStyles,
  Button,
  Box,
  TablePagination
} from "@material-ui/core";
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon
} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  tableContainer: {
    maxHeight: 440
  },
  noteCell: {
    maxWidth: 300,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  actionsCell: {
    width: 120
  },
  multiDeleteButton: {
    marginBottom: theme.spacing(2)
  },
  tableHead: {
    backgroundColor: theme.palette.primary.main
  },
  tableHeadCell: {
    color: theme.palette.primary.contrastText
  },
  paginationRoot: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}));

function DocumentTable({
  documents,
  onView,
  onEdit,
  onDelete,
  selectedDocuments,
  setSelectedDocuments,
  onMultiDelete,
  isIssaraStaff
}) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [paginatedDocuments, setPaginatedDocuments] = useState([]);

  // Update paginated documents whenever page, rowsPerPage, or documents change
  useEffect(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    setPaginatedDocuments(documents.slice(startIndex, endIndex));
  }, [page, rowsPerPage, documents]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectOne = id => {
    const selectedIndex = selectedDocuments.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedDocuments, id];
    } else {
      newSelected = selectedDocuments.filter(docId => docId !== id);
    }

    setSelectedDocuments(newSelected);
  };

  const isSelected = id => selectedDocuments.indexOf(id) !== -1;

  return (
    <>
      {selectedDocuments.length > 0 && isIssaraStaff && (
        <Box
          display="flex"
          justifyContent="flex-end"
          className={classes.multiDeleteButton}
        >
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={onMultiDelete}
          >
            Delete Selected ({selectedDocuments.length})
          </Button>
        </Box>
      )}

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="document table">
          <TableHead style={{ backgroundColor: "#005b4c" }}>
            <TableRow>
              {isIssaraStaff && (
                <TableCell
                  padding="checkbox"
                  style={{ color: "white" }}
                ></TableCell>
              )}
              <TableCell style={{ color: "white" }}>Name</TableCell>
              <TableCell style={{ color: "white" }}>Size</TableCell>
              <TableCell style={{ color: "white" }}>Created At</TableCell>
              {isIssaraStaff && (
                <TableCell style={{ color: "white" }}>Permission</TableCell>
              )}
              <TableCell style={{ color: "white" }}>Note</TableCell>
              <TableCell
                align="center"
                className={classes.actionsCell}
                style={{ color: "white" }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedDocuments.length > 0 ? (
              paginatedDocuments.map(doc => {
                const isItemSelected = isSelected(doc.id);

                return (
                  <TableRow
                    key={doc.id}
                    hover
                    role="checkbox"
                    selected={isItemSelected}
                  >
                    {isIssaraStaff && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={() => handleSelectOne(doc.id)}
                        />
                      </TableCell>
                    )}
                    <TableCell component="th" scope="row">
                      {doc.name}
                    </TableCell>
                    <TableCell>{doc.size}</TableCell>
                    <TableCell>{doc.created_at}</TableCell>
                    {isIssaraStaff && <TableCell>{doc.permission}</TableCell>}
                    <TableCell className={classes.noteCell}>
                      <Tooltip title={doc.description} placement="top-start">
                        <Typography variant="body2">
                          {doc.description}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="View">
                        <IconButton size="small" onClick={() => onView(doc)}>
                          <VisibilityIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      {isIssaraStaff && (
                        <>
                          <Tooltip title="Edit">
                            <IconButton
                              size="small"
                              onClick={() => onEdit(doc)}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              size="small"
                              onClick={() => onDelete(doc)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={isIssaraStaff ? 6 : 4} align="center">
                  No documents found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={documents.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

export default DocumentTable;
DocumentTable.propTypes = {
  documents: PropTypes.array,
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  selectedDocuments: PropTypes.array,
  setSelectedDocuments: PropTypes.func,
  onMultiDelete: PropTypes.func,
  isIssaraStaff: PropTypes.bool
};