import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import moment from "moment";
import Box from "@material-ui/core/Box";
import loginStore from "../../redux/stores/LoginStore";
import GrievanceForm from "./GrievanceForm";
import SummaryComponent from "./SummaryComponent";
import axios from "axios";

const GrievanceComponent = (props) => {
  const data = props.data;
  const context = props.context;
  const getDaysActive = props.getDaysActive;
  const getStatusColor = props.getStatusColor;
  const user = loginStore.getLoginUser();
  const [grievanceData, setGrievanceData] = useState({});

  useEffect(() => {
    if (loginStore.isGlobalPartner()) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/supplier-kpis/${data.id}/grievance/`
        )
        .then((response) => {
          if (response.data.length > 0) {
            setGrievanceData(response.data[0]);
          }
        });
    }
  }, [props.data]);
  return (
    <GridContainer spacing={1}>
      <GridItem xs={user.groups.includes("Supplier") ? 4 : 8}>
        <Box>
          <GridItem>
            <p style={{ margin: 0 }}>
              <b>{context.t("KPI ID :")}</b>
              {data.id || context.t("-")}
            </p>
          </GridItem>
          <GridItem>
            <p style={{ margin: 0 }}>
              <b>{context.t("KPI :")}</b>
              {data.description || context.t("-")}
            </p>
          </GridItem>
          <GridItem>
            <p style={{ margin: 0 }}>
              <b>{context.t("Opened since: ")}</b>
              {(data.opened_at && moment(data.opened_at).format("LL")) ||
                context.t("-")}
            </p>
          </GridItem>
          <GridItem>
            {user.groups.includes("Supplier") ? (
              data.affected_workers == null ||
              (data.affected_workers <= 10 &&
                data.affected_workers !== undefined) ? (
                <p style={{ margin: 0 }}>
                  <b>{context.t("Affected workers:")}</b> {context.t("1-10")}
                </p>
              ) : data.affected_workers > 10 &&
                data.affected_workers <= 50 &&
                data.affected_workers !== undefined ? (
                <p style={{ margin: 0 }}>
                  <b>{context.t("Affected workers:")}</b> {context.t("11-50")}
                </p>
              ) : data.affected_workers > 50 &&
                data.affected_workers <= 100 &&
                data.affected_workers !== undefined ? (
                <p style={{ margin: 0 }}>
                  <b>{context.t("Affected workers:")}</b> {context.t("51-100")}
                </p>
              ) : data.affected_workers > 100 &&
                data.affected_workers <= 500 &&
                data.affected_workers !== undefined ? (
                <p style={{ margin: 0 }}>
                  <b>{context.t("Affected workers:")}</b> {context.t("101-500")}
                </p>
              ) : data.affected_workers > 500 &&
                data.affected_workers <= 1000 &&
                data.affected_workers !== undefined ? (
                <p style={{ margin: 0 }}>
                  <b>{context.t("Affected workers:")}</b>{" "}
                  {context.t("501-1000")}
                </p>
              ) : data.affected_workers > 1000 &&
                data.affected_workers !== undefined ? (
                <p style={{ margin: 0 }}>
                  <b>{context.t("Affected workers:")}</b> {context.t("1000+")}
                </p>
              ) : null
            ) : (
              <p style={{ margin: 0 }}>
                <b>{context.t("Affected workers: ")}</b>
                {data.affected_workers !== undefined &&
                data.affected_workers !== null
                  ? data.affected_workers
                  : context.t("-")}
              </p>
            )}
          </GridItem>
          {user.groups.includes("Supplier") && !data.closed_at && (
            <>
              <GridItem xs={12}>
                <p style={{ margin: 0 }}>
                  <b>{context.t("Level: ")}</b>
                  {data.level}
                </p>
              </GridItem>

              <GridItem xs={12}>
                <p style={{ margin: 0 }}>
                  <b>{context.t("Day active: ")}</b>
                  {getDaysActive(data.opened_at)}
                </p>
              </GridItem>

              {data.remediation_notes && (
                <>
                  <GridItem
                    xs={12}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <p style={{ margin: 0, marginRight: "8px" }}>
                      <b>{context.t("Status: ")}</b>
                    </p>
                    <div
                      className="statusIndicator"
                      style={{
                        margin: "left",
                        backgroundColor: getStatusColor(
                          getDaysActive(data.opened_at),
                          data.status
                        ),
                      }}
                    ></div>
                  </GridItem>

                  {user.groups.includes("Supplier") ? null : (
                    <GridItem xs={12}>
                      <p style={{ margin: 0, textAlign: "justify" }}>
                        <b>{context.t("Issues: ")}</b> {data.remediation_notes}
                      </p>
                    </GridItem>
                  )}
                </>
              )}
            </>
          )}

          {user.groups.includes("Supplier") && !data.closed_at
            ? data.remediation_notes && (
                <GridItem xs={12}>
                  <p style={{ margin: 0, textAlign: "justify" }}>
                    <b>{context.t("Issues:")}</b> {data.remediation_notes}
                  </p>
                </GridItem>
              )
            : null}

          {user.groups.includes("Supplier")
            ? null
            : data.remediation_notes && (
                <GridItem xs={12}>
                  <h5>
                    <b>{context.t("Remediation Notes (Summary):")}</b>
                  </h5>
                </GridItem>
              )}

          {user.groups.includes("Supplier")
            ? null
            : data.remediation_notes && (
                <GridItem xs={12}>
                  <p style={{ margin: 0, textAlign: "justify" }}>
                    <b>{context.t("Issues:")}</b> {data.remediation_notes}
                  </p>
                </GridItem>
              )}
          {user.groups.includes("Supplier")
            ? null
            : data.remediation_action && (
                <GridItem xs={12}>
                  <p style={{ margin: 0, textAlign: "justify" }}>
                    <b>{context.t("Action:")}</b> {data.remediation_action}
                  </p>
                </GridItem>
              )}
          {user.groups.includes("Supplier")
            ? null
            : data.remediation_validation && (
                <GridItem xs={12}>
                  <p style={{ margin: 0, textAlign: "justify" }}>
                    <b>{context.t("Worker Validation:")}</b>
                    {data.remediation_validation}
                  </p>
                </GridItem>
              )}
          {user.groups.includes("Supplier")
            ? null
            : data.remediation_results && (
                <GridItem xs={12}>
                  <p style={{ margin: 0, textAlign: "justify" }}>
                    <b>{context.t("Results:")}</b> {data.remediation_results}
                  </p>
                </GridItem>
              )}

          {"list_of_text_fields" in grievanceData &&
          loginStore.isGlobalPartner() ? (
            <GridContainer>
              <GridItem xs={12}>
                <SummaryComponent
                  data={grievanceData.list_of_text_fields.remediations}
                  context={context}
                  title="Supplier's notes on case remediation"
                  header="Remediation: "
                />
              </GridItem>
              <GridItem xs={12}>
                <SummaryComponent
                  data={grievanceData.list_of_text_fields.system_strengthenings}
                  context={context}
                  title="Supplier's notes on systems strengthening and continuous improvement related to this worker-reported issue:"
                  header="System Strengthenings: "
                />
              </GridItem>
            </GridContainer>
          ) : null}
        </Box>
      </GridItem>
      <GridItem xs={8} md={8}>
        {loginStore.isSupplier() ? (
          <GrievanceForm context={context} kpi_id={data.id} />
        ) : null}
      </GridItem>
    </GridContainer>
  );
};

GrievanceComponent.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default GrievanceComponent;
