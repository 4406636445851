import {
  defaultFont,
  container,
  containerFluid,
  primaryColor,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

const footerStyle = {
  block: {},
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    margin: "0",
    fontSize: "14px",
    float: "right!important",
    padding: "15px",
  },
  footer: {
    color: "#fff",
    // textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)",
    bottom: "0",
    borderTop: "1px solid " + grayColor[15],
    padding: "15px 0",
    ...defaultFont,
    zIndex: 4,
    width: "100vw",
    margin: 0,
    padding: 0,
  },
  footerContainer: {
    display: "flex",
    width: "100%",
    "@media (max-width: 960px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  leftColumn: {
    width: "41%",
    "@media (max-width: 960px)": {
      width: "100%",
    },
  },
  rightColumn: {
    width: "59%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 960px)": {
      width: "100%",
    },
  },
  textContainer: {
    margin: "10px 0",
    padding: "10px",
    fontSize: "14px",
    "@media (max-width: 960px)": {
      fontSize: "12px",
    },
    "@media (max-width: 600px)": {
      fontSize: "10px",
    },
    "@media (max-width: 375px)": {
      color: "black",
    },
  },
  container: {
    zIndex: 3,
    ...container,
    position: "relative",
  },
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    position: "relative",
  },
  a: {
    color: primaryColor[0],
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0",
    width: "auto",
  },
  whiteColor: {
    "&,&:hover,&:focus": {
      color: whiteColor,
    },
  },
  link: {
    color: "#148696",
  },
};
export default footerStyle;
