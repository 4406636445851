import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  CssBaseline,
  Container,
  Paper,
  Tabs,
  Tab,
  Box,
  Typography,
  makeStyles,
  CircularProgress
} from "@material-ui/core";
import DocumentTable from "components/Documents/DocumentTable";
import DocumentUploader from "components/Documents/DocumentUploader";
import ViewDocumentDialog from "components/Documents/ViewDocumentDialog";
import EditDocumentDialog from "components/Documents/EditDocumentDialog";
import DeleteConfirmationDialog from "components/Documents/DeleteConfirmationDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSupplierDocuments,
  createSupplierDocument,
  updateSupplierDocument,
  deleteSupplierDocument
} from "redux/stores/suppliers/supplierDocumentSlice";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(4)
  },
  header: {
    padding: theme.spacing(3, 2),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  tabContent: {
    padding: theme.spacing(3)
  },
  uploadButton: {
    margin: theme.spacing(2, 0)
  }
}));

// Permissions
const DOCUMENT_VIEW_PERMISSION_MAPPER_BY_USER_TYPE = {
  issara: ["Both", "SP Only", "Supplier Only"],
  sp: ["Both", "SP Only"],
  supplier: ["Both", "Supplier Only"]
};

function SupplierDocuments({ supplierId, userType }, context) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isIssaraStaff = userType == "issara";
  const { list: initialDocuments, loading } = useSelector(
    state => state.supplierDocumentReducer
  );
  const [tabValue, setTabValue] = useState(0);
  const [isUploaderOpen, setIsUploaderOpen] = useState(false);
  const [documents, setDocuments] = useState(initialDocuments);
  const [viewDocument, setViewDocument] = useState(null);
  const [editDocument, setEditDocument] = useState(null);
  const [deleteDocument, setDeleteDocument] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [isMultiDeleteOpen, setIsMultiDeleteOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchSupplierDocuments({ id: supplierId }));
  }, [supplierId]);

  useEffect(() => {
    setDocuments(initialDocuments);
  }, [initialDocuments]);

  const documentTypes = [
    { key: "SR", value: "Reports" },
    {
      key: "SPA",
      value: "Photos"
    },
    {
      key: "SVA",
      value: "Videos"
    }
  ];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAddDocument = newDocument => {
    setDocuments([
      ...documents,
      {
        id: documents.length + 1,
        ...newDocument
      }
    ]);

    const formData = new FormData();

    Object.keys(newDocument).forEach(key => {
      formData.append(key, newDocument[key]);
    });
    dispatch(createSupplierDocument({ id: supplierId, payload: formData }));
    setIsUploaderOpen(false);
  };

  const handleUpdateDocument = updatedDoc => {
    setDocuments(
      documents.map(doc => (doc.id === updatedDoc.id ? updatedDoc : doc))
    );
    dispatch(
      updateSupplierDocument({
        id: supplierId,
        payload: { ...updatedDoc, document_id: updatedDoc.id }
      })
    );
    setEditDocument(null);
  };

  const handleDeleteDocument = id => {
    setDocuments(documents.filter(doc => doc.id !== id));
    dispatch(deleteSupplierDocument({ id: supplierId, document_ids: [id] }));
    setDeleteDocument(null);
  };

  const handleMultiDelete = () => {
    setDocuments(documents.filter(doc => !selectedDocuments.includes(doc.id)));
    dispatch(
      deleteSupplierDocument({
        id: supplierId,
        document_ids: selectedDocuments
      })
    );
    setSelectedDocuments([]);
    setIsMultiDeleteOpen(false);
  };

  const toggleUploader = () => {
    setIsUploaderOpen(!isUploaderOpen);
  };

  const filteredDocuments = documents.filter(
    doc =>
      doc.type === documentTypes[tabValue].key &&
      DOCUMENT_VIEW_PERMISSION_MAPPER_BY_USER_TYPE[userType].includes(
        doc.permission
      )
  );

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <CssBaseline />
      <Typography variant="h4" style={{ cursor: "pointer" }}>
        {context.t("Documents")}
      </Typography>
      <Container maxWidth={false}>
        {isUploaderOpen ? (
          <DocumentUploader
            onAddDocument={handleAddDocument}
            onCancel={() => setIsUploaderOpen(false)}
          />
        ) : (
          <div className={classes.root}>
            {isIssaraStaff && (
              <Box display="flex" justifyContent="flex-end" mb={2}>
                <Typography
                  variant="button"
                  style={{ cursor: "pointer" }}
                  onClick={toggleUploader}
                >
                  {context.t("Upload New Document")}
                </Typography>
              </Box>
            )}

            <Paper elevation={2}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                {documentTypes.map((type, index) => (
                  <Tab
                    key={`${type.key}-${index}`}
                    disableRipple
                    label={
                      <Typography
                        variant="h5"
                        style={{ fontWeight: "bold", textTransform: "none" }}
                      >
                        {context.t(type.value)}
                      </Typography>
                    }
                  />
                ))}
              </Tabs>

              <Box className={classes.tabContent}>
                <DocumentTable
                  documents={filteredDocuments}
                  onView={setViewDocument}
                  onEdit={setEditDocument}
                  onDelete={setDeleteDocument}
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                  onMultiDelete={() => setIsMultiDeleteOpen(true)}
                  isIssaraStaff={isIssaraStaff}
                />
              </Box>
            </Paper>
          </div>
        )}
      </Container>

      {/* Dialogs */}
      <ViewDocumentDialog
        document={viewDocument}
        open={!!viewDocument}
        onClose={() => setViewDocument(null)}
        isIssaraStaff={isIssaraStaff}
      />

      <EditDocumentDialog
        document={editDocument}
        open={!!editDocument}
        onClose={() => setEditDocument(null)}
        onSave={handleUpdateDocument}
      />

      <DeleteConfirmationDialog
        open={!!deleteDocument}
        onClose={() => setDeleteDocument(null)}
        onConfirm={() => handleDeleteDocument(deleteDocument?.id)}
        title={context.t("Delete Document")}
        content={context.t(
          `Are you sure you want to delete "${deleteDocument?.name}"?`
        )}
      />

      <DeleteConfirmationDialog
        open={isMultiDeleteOpen}
        onClose={() => setIsMultiDeleteOpen(false)}
        onConfirm={handleMultiDelete}
        title={context.t("Delete Multiple Documents")}
        content={context.t(
          `Are you sure you want to delete ${selectedDocuments.length} selected document(s)?`
        )}
      />
    </>
  );
}

export default SupplierDocuments;

SupplierDocuments.contextTypes = {
  t: PropTypes.func.isRequired
};

SupplierDocuments.propTypes = {
  supplierId: PropTypes.number,
  userType: PropTypes.string
};
