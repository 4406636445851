// 2 of 5. SP Main Goals Form
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./css/style1.css";
import { useDispatch, useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import GridContainer from "components/Grid/GridContainer";
import Grid from "@material-ui/core/Grid";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { mainGoalsAndPriority } from "redux/actions/SpWorkplanFormAction.js";
import { workplanIsloadingSuccess } from "redux/actions/SpWorkplanFormAction";
import swal from "sweetalert";
import LinearProgress from "@material-ui/core/LinearProgress";
import HttpService from "../../../services/HttpService";

let gl_dontShow = false;
let gl_wp = "";
let gl_maingoals = "";
let gl_mainpriority = "";
let gl_actionednEscalated = "";
let gl_prioritySupplierCountriesRegions = "";

const BottomBtn = {
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "20px",
};

export default function SPMainGoalsForm(props, context) {
  const [ethicalRecruitment, setEthicalRecruitment] = useState(gl_maingoals);
  const [mainpriority, setMainPriority] = useState(gl_mainpriority);
  const [actionednEscalated, setActionednEscalated] = useState(
    gl_actionednEscalated
  );
  const [
    prioritySupplierCountriesRegions,
    setPrioritySupplierCountriesRegions,
  ] = useState(gl_prioritySupplierCountriesRegions);
  const [workplanID, setWorkplanID] = useState();
  const [WPData, setWPData] = useState([]);
  const workplanresponse = useSelector((state) => state.workplanReducer.items);
  const [mainGoals, setMainGoals] = useState([]);
  const isRunned = useRef(false);
  const dispatch = useDispatch();

  const callApi = () => {
    dispatch(workplanIsloadingSuccess());
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/sp_work_plan/`,
      (response) => {
        setWPData(response);
        setWorkplanID(response.id);
        if (
          response &&
          Array.isArray(response.main_goals) &&
          response.main_goals[0]
        ) {
          setEthicalRecruitment(response.main_goals[0].ethical_recruitment);
          setMainPriority(response.main_goals[0].partnership_areas);
          setActionednEscalated(response.main_goals[0].worker_voice_escalation);
          setPrioritySupplierCountriesRegions(
            response.main_goals[0].priority_suppliers_countries_regions
          );
          setMainGoals(response.main_goals[0]);
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  useEffect(() => {
    if (isRunned.current) return;
    callApi();
    isRunned.current = true;
  }, []);

  useEffect(() => {
    gl_maingoals = ethicalRecruitment;
    gl_mainpriority = mainpriority;
    gl_actionednEscalated = actionednEscalated;
    gl_prioritySupplierCountriesRegions = prioritySupplierCountriesRegions;
    gl_wp = workplanID;
  });

  function mainGoalHandler(e) {
    setEthicalRecruitment(e.target.value);
    document.getElementById("main-goal").style.border = "1px solid black";
    document.getElementById("ethicalRecruitment").style.visibility = "hidden";
  }

  function mainMainPriorityHandler(e) {
    setMainPriority(e.target.value);
    document.getElementById("main-priority").style.border = "1px solid black";
    document.getElementById("mainpriority").style.visibility = "hidden";
  }

  function actionednEscalatedHandler(e) {
    setActionednEscalated(e.target.value);
    document.getElementById("actioned-escalated").style.border =
      "1px solid black";
    document.getElementById("actionednEscalated").style.visibility = "hidden";
  }

  function prioritySupplierCountriesRegionsHandler(e) {
    setPrioritySupplierCountriesRegions(e.target.value);
    document.getElementById("text-4").style.border = "1px solid black";
    document.getElementById(
      "prioritySupplierCountriesRegions"
    ).style.visibility = "hidden";
  }

  function mainGoalsAndPriorityPartnership() {
    if (!workplanID || workplanID == undefined) {
      setWorkplanID(workplanresponse.id);
    } else {
      const mainGoalPayload = {
        workplan: workplanID,
        ethical_recruitment: ethicalRecruitment,
        partnership_areas: mainpriority,
        worker_voice_escalation: actionednEscalated,
        priority_suppliers_countries_regions: prioritySupplierCountriesRegions,
      };
      dispatch(mainGoalsAndPriority(mainGoalPayload));
      callApi();
    }
  }

  const classes = props.useStyles();

  const [dontShow, setDontShow] = useState(gl_dontShow);

  useEffect(() => {
    gl_dontShow = dontShow;
  });

  const NextHandler = () => {
    if (gl_dontShow == false) {
      if (
        mainGoals !== null &&
        mainGoals !== undefined &&
        mainGoals.length !== 0 &&
        (mainGoals.ethical_recruitment !== ethicalRecruitment ||
          mainGoals.partnership_areas !== mainpriority ||
          mainGoals.worker_voice_escalation !== actionednEscalated ||
          mainGoals.priority_suppliers_countries_regions !==
            prioritySupplierCountriesRegions)
      ) {
        swal({
          text: context.t("Changes have not been saved."),
          icon: "warning",
          buttons: {
            cancel: context.t("Cancel"),
            proceed: context.t("Proceed Anyway"),
            save: context.t("Save"),
          },
          dangerMode: true,
        }).then((willSave) => {
          switch (willSave) {
            case "proceed":
              props.next();
              break;
            case "save":
              mainGoalsAndPriorityPartnership();
              break;
          }
        });
      } else {
        props.next();
      }
    } else {
      props.next();
    }
  };

  return (
    <Container>
      {WPData.length == 0 ? (
        <LinearProgress />
      ) : (
        <div className="workplan-box">
          <Grid>
            <h3>
              {context.t("2 of 5. Main Goals and Priority Partnership Areas")}
            </h3>
            <p style={{ color: "rgba(0, 0, 0, 0.7)", marginTop: "10px" }}>
              {context.t(
                "Summary of the Partner’s main goals in responsible sourcing and ethical recruitment over the next year and the next three years:"
              )}
            </p>
            <TextareaAutosize
              id="main-goal"
              aria-label="minimum height"
              className={classes.textArea}
              minRows={10}
              value={ethicalRecruitment}
              onChange={mainGoalHandler}
            />
            <span
              id="ethicalRecruitment"
              style={{ visibility: "hidden", color: "red" }}
            >
              {context.t(
                "What are the company's main goals in responsible sourcing and ethical recruitment over the next 3 years?"
              )}
            </span>
            <p style={{ color: "rgba(0, 0, 0, 0.7)", marginTop: "10px" }}>
              {context.t(
                "Partner’s main priority areas of partnership with Issara Institute:"
              )}
            </p>
            <TextareaAutosize
              id="main-priority"
              aria-label="minimum height"
              className={classes.textArea}
              minRows={10}
              value={mainpriority}
              onChange={mainMainPriorityHandler}
            />
            <span
              id="mainpriority"
              style={{ visibility: "hidden", color: "red" }}
            >
              {context.t(
                "What are the company's main priority areas of partnership with Issara Institute?"
              )}
            </span>
            <p style={{ color: "rgba(0, 0, 0, 0.7)", marginTop: "10px" }}>
              {context.t(
                "How worker voice via Inclusive Labor Monitoring (ILM) will be actioned and escalated at Partner:"
              )}
            </p>
            <TextareaAutosize
              id="actioned-escalated"
              aria-label="minimum height"
              className={classes.textArea}
              minRows={10}
              value={actionednEscalated}
              onChange={actionednEscalatedHandler}
            />
            <span
              id="actionednEscalated"
              style={{ visibility: "hidden", color: "red" }}
            >
              {context.t(
                "How worker voice via Inclusive Labor Monitoring (ILM) will be actioned and escalated at Partner:"
              )}
            </span>
            <p style={{ color: "rgba(0, 0, 0, 0.7)", marginTop: "10px" }}>
              {context.t(
                "Priority suppliers, countries, and/or regions for continued focus or expansion:"
              )}
            </p>
            <TextareaAutosize
              id="text-4"
              aria-label="minimum height"
              className={classes.textArea}
              minRows={10}
              value={prioritySupplierCountriesRegions}
              onChange={prioritySupplierCountriesRegionsHandler}
            />
            <span
              id="prioritySupplierCountriesRegions"
              style={{ visibility: "hidden", color: "red" }}
            >
              {context.t(
                "Priority suppliers, countries, and/or regions for continued focus or expansion:"
              )}
            </span>
          </Grid>

          <GridContainer>
            <Grid item xs={6} sm={6} md={8} lg={8}>
              <Button
                onClick={props.back}
                variant="contained"
                style={{ marginLeft: "5px" }}
              >
                {context.t("Back")}
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} style={BottomBtn}>
              <Button
                onClick={mainGoalsAndPriorityPartnership}
                variant="contained"
                style={{ marginRight: "15px", backgroundColor: "#03A9F4" }}
              >
                {context.t("Save")}
              </Button>
              <Button
                onClick={NextHandler}
                variant="contained"
                style={{ marginRight: "5px" }}
              >
                {context.t("Next")}
              </Button>
            </Grid>
          </GridContainer>
        </div>
      )}
    </Container>
  );
}

SPMainGoalsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
