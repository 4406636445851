import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Divider from "@material-ui/core/Divider";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { fetchSuppliers } from "../../redux/actions/SupplierActions";
import { fetchRecruiters } from "../../redux/actions/RecruiterActions";
import { fetchStrategicPartners } from "../../redux/actions/StrategicPartnerActions";

const customStyles = {
  paper: {
    backgroundColor: "white",
    padding: 30,
    webkitBoxShadow: "5px 6px 10px 0px rgba(0,0,0,0.27)",
    mozBoxShadow: "5px 6px 10px 0px rgba(0,0,0,0.27)",
    boxShadow: "5px 6px 10px 0px rgba(0,0,0,0.27)",
  },
  modal: {
    display: "block",
    maxWidth: "70%",
    height: "100%",
    overflowWrap: "break-word",
    overflowY: "auto;",
    overflowX: "none;",
    paddingBottom: "20px",
  },
  spanLabel: {
    fontWeight: 500,
  },
  button: {
    position: "absolute",
    padding: 0,
    margin: 0,
    top: "40px",
    right: "40px",
    scrollbars: "none",
  },
  preBlock: {
    whiteSpace: "pre-wrap",
    wordBreak: "keep-all",
    scroll: "none",
    width: "100%",
  },
};

const useStyles = makeStyles(customStyles);

export default function BusinessResponseModal(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const businessResponses = useSelector(
    (state) => state.businessResponsesReducer.items
  );
  const users = useSelector((state) => state.usersReducer.items);
  const suppliers = useSelector((state) => state.suppliersReducer.items);
  const recruiters = useSelector((state) => state.recruitersReducer.items);
  const strategicPartners = useSelector(
    (state) => state.strategicPartnerReducer.items
  );
  const interactionTypes = useSelector(
    (state) => state.responseInteractionTypesReducer.items
  );
  const [data, setData] = useState({});

  useEffect(() => {
    // Fetch recruiters
    if (recruiters.length === 0) {
      dispatch(fetchRecruiters());
    }
    // Fetch strategicPartners
    if (strategicPartners.length === 0) {
      dispatch(fetchStrategicPartners());
    }

    // fetch suppliers
    if (suppliers.length === 0) {
      dispatch(fetchSuppliers());
    }

    if (businessResponses[props.value]) {
      setData(businessResponses[props.value]);
    } else {
      if (!props.value) return;
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/business-responses/${props.value}/`
        )
        .then((response) => {
          setData(response.data);
        })
        .then((error) => {
          console.log(`Error fetching business response ${props.value}`, error);
        });
    }
  }, [props.value]);

  const getNames = (ids, items) => {
    const names = ids
      .map((id) => {
        const item = items.find((i) => i.id === id);
        return item?.name;
      })
      .filter(Boolean)
      .join(", ");

    return names;
  };

  const getInteractionTypeName = (id) => {
    const interactionType = interactionTypes.find((element) => {
      return element.id === id;
    });
    return interactionType ? interactionType.name : "-";
  };

  const getIssaraNames = (ids) => {
    let names = [];
    const filteredUsers = ids.map((id) => {
      return users.find((user) => {
        return user.id === id;
      });
    });
    names = filteredUsers.map((item) => {
      return item.first_name;
    });
    return names.length > 0 ? names.join(", ") : "-";
  };

  return (
    <Modal open={props.open} onClose={props.onClose} className={classes.modal}>
      <div className={classes.paper}>
        <h3 style={{ marginTop: 0 }}>{`Business Response #${props.value}`}</h3>
        <Button
          simple
          color="danger"
          className={classes.button}
          onClick={() => props.onClose()}
        >
          <CloseIcon />
        </Button>
        <Divider />
        <h5>Participants:</h5>
        <div>
          <span className={classes.spanLabel}>Suppliers:</span>{" "}
          {data && data.supplier_names}
        </div>
        <div>
          <span className={classes.spanLabel}>Suppliers Notes:</span>
          <GridItem xs={12} md={12} lg={12}>
            <div className={classes.preBlock}>
              {(data && data?.suppliers_notes) || "-"}
            </div>
          </GridItem>
        </div>
        <div>
          <span className={classes.spanLabel}>Recruiters:</span>{" "}
          {data && data.recruiter_names}
        </div>
        <div>
          <span className={classes.spanLabel}>Recruiters Notes:</span>
          <GridItem xs={12} md={12} lg={12}>
            <div className={classes.preBlock}>
              {(data && data.recruiters_notes) || "-"}
            </div>
          </GridItem>
        </div>
        <div>
          <span className={classes.spanLabel}>Global Buyers:</span>{" "}
          {data && data.global_buyer_names}
        </div>
        <div>
          <span className={classes.spanLabel}>Global Buyers Notes:</span>
          <GridItem xs={12} md={12} lg={12}>
            <div className={classes.preBlock}>
              {(data && data?.global_buyers_notes) || "-"}
            </div>
          </GridItem>
        </div>
        <div>
          <span className={classes.spanLabel}>Other Participants Notes:</span>
          <GridItem xs={12} md={12} lg={12}>
            <div className={classes.preBlock}>
              {(data && data?.other_parties_notes) || "-"}
            </div>
          </GridItem>
        </div>
        <Divider />
        <h5>Event Details:</h5>
        <div>
          <span className={classes.spanLabel}>Type:</span>{" "}
          {data && getInteractionTypeName(data?.event_interaction_type)}
        </div>
        <div>
          <span className={classes.spanLabel}>Location:</span>{" "}
          {data && data?.event_location}
        </div>
        <div>
          <span className={classes.spanLabel}>Date:</span>{" "}
          {(data &&
            data.event_date &&
            new Date(data?.event_date).toDateString()) ||
            "-"}
        </div>
        <div>
          <span className={classes.spanLabel}>Details:</span>
          <GridItem xs={12} md={12} lg={12}>
            <div className={classes.preBlock}>
              {(data && data?.event_details) || "-"}
            </div>
          </GridItem>
        </div>
        <Divider />
        <h5>Agreed Next Steps:</h5>
        <div>
          <span className={classes.spanLabel}>Suppliers Next Steps:</span>
          <GridItem xs={12} md={12} lg={12}>
            <div className={classes.preBlock}>
              {(data && data?.suppliers_next_steps) || "-"}
            </div>
          </GridItem>
        </div>
        <div>
          <span className={classes.spanLabel}>Suppliers Focal Points:</span>{" "}
          {(data && data?.suppliers_focal_points) || "-"}
        </div>
        <div>
          <span className={classes.spanLabel}>
            Suppliers Next Steps Deadline:
          </span>{" "}
          {(data &&
            data.suppliers_next_steps_deadline &&
            new Date(data?.suppliers_next_steps_deadline).toDateString()) ||
            "-"}
        </div>
        <div>
          <span className={classes.spanLabel}>Recruiters Next Steps:</span>{" "}
          <GridItem xs={12} md={12} lg={12}>
            <div className={classes.preBlock}>
              {(data && data?.recruiters_next_steps) || "-"}
            </div>
          </GridItem>
        </div>
        <div>
          <span className={classes.spanLabel}>Recruiters Focal Points:</span>{" "}
          {(data && data?.recruiters_focal_points) || "-"}
        </div>
        <div>
          <span className={classes.spanLabel}>
            Recruiters Next Steps Deadline:
          </span>{" "}
          {(data &&
            data.recruiters_next_steps_deadline &&
            new Date(data?.recruiters_next_steps_deadline).toDateString()) ||
            "-"}
        </div>
        <div>
          <span className={classes.spanLabel}>Global Buyers Next Steps:</span>
          <GridItem xs={12} md={12} lg={12}>
            <div className={classes.preBlock}>
              {(data && data.global_buyers_next_steps) || "-"}
            </div>
          </GridItem>
        </div>
        <div>
          <span className={classes.spanLabel}>Global Buyers Focal Points:</span>{" "}
          {(data && data.global_buyers_focal_points) || "-"}
        </div>
        <div>
          <span className={classes.spanLabel}>
            Global Buyers Next Steps Deadline:
          </span>{" "}
          {(data &&
            data.global_buyers_next_steps_deadline &&
            new Date(data?.global_buyers_next_steps_deadline).toDateString()) ||
            "-"}
        </div>
        <div>
          <span className={classes.spanLabel}>Issara Next Steps:</span>
          <GridItem xs={12} md={12} lg={12}>
            <div className={classes.preBlock}>
              {(data && data.issara_next_steps) || "-"}
            </div>
          </GridItem>
        </div>
        {/* <div>
          <span className={classes.spanLabel}>Issara Focal Points:</span>{" "}
          {data && getIssaraNames(data?.issara_focal_points)}
        </div> */}
        <div>
          <span className={classes.spanLabel}>Issara Next Steps Deadline:</span>{" "}
          {(data &&
            data.issara_next_steps_deadline &&
            new Date(data?.issara_next_steps_deadline).toDateString()) ||
            "-"}
        </div>
      </div>
    </Modal>
  );
}
