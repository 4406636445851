import React, { useState } from "react";
import swal from "sweetalert"; // Import sweetalert
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Button from "components/CustomButtons/Button.js";
import styles from "./ForgotPasswordViewStyles";
import * as LoginActions from "redux/actions/LoginActions";
import landingPageImage from "assets/img/landing_page_image.png";

const useStyles = makeStyles(styles);

export default function ForgotPasswordView(props) {
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const [payload, setPayload] = useState({ email: "" });

  const handleResetPassword = () => {
    if (!payload.email) {
      errorAlert("Please enter your email to receive a password reset link.");
      return;
    }

    setSaving(true);

    LoginActions.requestResetPassword(
      payload.email,
      (resp) => {
        console.log("resetPassword-----------------Resp", resp);
        setSaving(false); // Reset saving state
        successAlert();
      },
      (error) => {
        setSaving(false);
        let errorMessage =
          error.message +
          (error.response?.data?.email ? " " + error.response.data.email : "");
        errorAlert(errorMessage);
      }
    );
  };

  const successAlert = () => {
    swal({
      title: "Well Done!!",
      text: "A reset link has been sent to your email",
      icon: "success",
      buttons: {
        confirm: {
          text: "OK",
          className: classes.button + " " + classes.success,
        },
      },
    }).then(() => {
      if (props.lostFocus) {
        props.lostFocus();
      }
      window.location = `/admin/login-page`;
    });
  };

  const errorAlert = (message) => {
    console.log("ERROR", message);
    swal({
      title: "Error",
      text: message,
      icon: "error",
      buttons: {
        confirm: {
          text: "OK",
          className: classes.button + " " + classes.success,
        },
      },
    });
  };

  return (
    <GridContainer justifyContent="center" alignItems="center">
      <div className={classes.loginSection}>
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem xs={12} sm={12} md={12}>
            <center>
              <form className={classes.formStyle}>
                <Card className={classes.card}>
                  <CardHeader className={classes.textCenter} color="rose">
                    <h4 className={classes.cardTitle}>Reset Password</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="Email"
                      id="email"
                      formControlProps={{ fullWidth: true }}
                      value={payload.email}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        type: "text",
                        autoComplete: "off",
                        onChange: (e) =>
                          setPayload({ ...payload, email: e.target.value }),
                      }}
                    />
                    <GridItem>
                      <br />
                    </GridItem>
                    <GridItem xs={12} md={12} lg={12}>
                      <Button
                        disabled={saving}
                        className={`${classes.center} ${classes.resetButton}`}
                        style={{
                          backgroundColor: "teal",
                          color: "white",
                          fontWeight: "bold",
                        }}
                        onClick={handleResetPassword}
                      >
                        Reset my Password
                      </Button>
                      <a
                        href="/admin/login-page"
                        className={classes.forgotPasswordLink}
                        style={{ marginLeft: "10px" }}
                      >
                        <small>
                          <i>Back to Login</i>
                        </small>
                      </a>
                    </GridItem>
                  </CardBody>
                </Card>
              </form>
            </center>
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.imageSection}>
        <div className={classes.imageOverlay}></div>
        <img
          src={landingPageImage}
          alt="Landing"
          className={classes.heroImage}
        />
      </div>
    </GridContainer>
  );
}
