import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Email from "@material-ui/icons/Email";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/enterCodePageStyle.js";
import image from "assets/img/landing_page_image.png";

import loginStore from "../../redux/stores/LoginStore";

const useStyles = makeStyles(styles);

export default function EnterCodePage() {
  const [code, setCode] = React.useState("");
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  React.useEffect(() => {
    setCardAnimation("");
  }, []);

  const handleChangeCode = (e) => {
    setCode(e.target.value);
  };

  const handleSend2FactorCode = () => {
    loginStore.do2FactorSendCode(code);
  };

  const classes = useStyles();

  return (
    <GridContainer justifyContent="center" alignItems="center">
      <div className={classes.imageSection}>
        <div className={classes.imageOverlay} />
        <img src={image} alt="background" className={classes.heroImage} />
      </div>
      <div className={classes.loginSection}>
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem xs={12} sm={12} md={12} className={classes.textCenter}>
            <h3 className={classes.title}>Enter Code</h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <center>
              <form className={classes.formStyle}>
                <Card
                  login
                  className={`${classes[cardAnimaton]} ${classes.card}`}
                >
                  <CardHeader className={`${classes.textCenter}`} color="rose">
                    <h4 className={classes.cardTitle}>Enter Code</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="2Factor Code (Check your Email)..."
                      id="code"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        onChange: handleChangeCode,
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    <Button
                      color="rose"
                      simple
                      size="lg"
                      block
                      onClick={handleSend2FactorCode}
                    >
                      Submit OTP
                    </Button>
                    <GridItem xs={12} md={12} lg={12}>
                      <a
                        href="/admin/login-page"
                        className={classes.forgotPasswordLink}
                        style={{ marginLeft: "10px" }}
                      >
                        <small>
                          <i>Back to Login</i>
                        </small>
                      </a>
                    </GridItem>
                  </CardFooter>
                </Card>
              </form>
            </center>
          </GridItem>
        </GridContainer>
      </div>
    </GridContainer>
  );
}
