import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Divider,
  makeStyles,
  Box,
  IconButton
} from "@material-ui/core";
import {
  Description as DescriptionIcon,
  Photo as PhotoIcon,
  Videocam as VideocamIcon
} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  label: {
    fontWeight: "bold",
    color: theme.palette.text.secondary
  },
  value: {
    wordBreak: "break-word"
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2)
  },
  icon: {
    fontSize: 60,
    color: theme.palette.primary.main
  }
}));

function ViewDocumentDialog({ document, open, onClose, isIssaraStaff }) {
  const classes = useStyles();

  if (!document) {
    return null;
  }

  const getDocumentIcon = type => {
    switch (type) {
      case "Reports":
        return <DescriptionIcon className={classes.icon} />;
      case "Photos":
        return <PhotoIcon className={classes.icon} />;
      case "Videos":
        return <VideocamIcon className={classes.icon} />;
      default:
        return <DescriptionIcon className={classes.icon} />;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="view-document-dialog-title"
    >
      <DialogTitle id="view-document-dialog-title">
        Document Details
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Box className={classes.iconContainer}>
          <IconButton
            component="a"
            href={document.file_path}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getDocumentIcon(document.type)}
          </IconButton>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center" gutterBottom>
              {document.name}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" className={classes.label}>
              Type:
            </Typography>
            <Typography variant="body1" className={classes.value}>
              {document.type}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" className={classes.label}>
              Size:
            </Typography>
            <Typography variant="body1" className={classes.value}>
              {document.size}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" className={classes.label}>
              Created At:
            </Typography>
            <Typography variant="body1" className={classes.value}>
              {document.created_at}
            </Typography>
          </Grid>

          {isIssaraStaff && (
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2" className={classes.label}>
                Permission:
              </Typography>
              <Typography variant="body1" className={classes.value}>
                {document.permission}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Divider className={classes.divider} />
            <Typography variant="subtitle2" className={classes.label}>
              Note:
            </Typography>
            <Typography variant="body1" className={classes.value}>
              {document.description || "No notes available"}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ViewDocumentDialog;

ViewDocumentDialog.propTypes = {
  document: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  isIssaraStaff: PropTypes.bool
};
