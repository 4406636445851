import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import SupplierKpiFilters from "views/suppliers/kpiupdates/SupplierKpiFilters";
import SupplierKpiTable from "views/suppliers/kpiupdates/SupplierKpiTable";
import SupplierKpiUpdateHistoryTable from "views/suppliers/kpiupdates/SupplierKpiUpdateHistoryTable";
import SupplierKpiViewInstance from "views/suppliers/kpiupdates/SupplierKpiViewInstance";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import { makeStyles } from "@material-ui/core/styles";
import loginStore from "redux/stores/LoginStore";
import { fetchItems as fetchSupplierKpis } from "redux/stores/supplierKpi/supplierKpiSlice";
import { fetchItems as fetchSupplierKpiUpdates } from "redux/stores/supplierKpi/supplierKpiUpdateSlice";
const customStyle = {
  ...customCheckboxRadioSwitch,
  checkRoot: {
    padding: 0
  },
  labelRoot: {
    margin: 0
  },
  button: {
    padding: 0,
    margin: 0
  },
  center: {
    textAlign: "center"
  },
  oneLine: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  callLink: {
    paddingRight: "0.35em",
    whiteSpace: "nowrap"
  }
};

const useStyles = makeStyles(customStyle);

export default function KpiUpdates(props) {
  const cssclasses = useStyles();
  let defaultOrganizationId = null;
  const { organization } = loginStore.getLoginUser();

  if (loginStore.isCSO()) {
    defaultOrganizationId = organization;
  }

  const [params, setParams] = useState({
    ordering: "-opened_at",
    suppliers: null,
    opened_at_after: null,
    opened_at_before: null,
    days_open__min: null,
    days_open__max: null,
    organization_id: defaultOrganizationId,
    show_closed: false,
    has_cases: true,
    id: null
  });

  const [modal, setModal] = useState({
    open: false,
    id: null
  });

  const { requestParams: cachedSupplierKpiParams } = useSelector(state => ({
    requestParams: state.supplierKpiReducer.requestParams
  }));
  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch(fetchSupplierKpis(cachedSupplierKpiParams));
    dispatch(fetchSupplierKpiUpdates(cachedSupplierKpiParams));
    setModal({ id: null, open: false });
  };

  return (
    <>
      {modal.open && (
        <SupplierKpiViewInstance
          open={modal.open}
          value={modal.id}
          onClose={handleModalClose}
          historical={modal.historical}
          onHistorySelect={historicalModalData =>
            setModal({ ...modal, ...historicalModalData })
          }
        />
      )}
      <SupplierKpiFilters
        params={params}
        setParams={setParams}
        cssclasses={cssclasses}
        csoDisplay={props?.csoDisplay || false}
      />
      <SupplierKpiTable
        defaultParams={params}
        cssclasses={cssclasses}
        history={props.history}
        modal={modal}
        setModal={setModal}
      />
      <SupplierKpiUpdateHistoryTable
        defaultParams={params}
        cssclasses={cssclasses}
        history={props.history}
        modal={modal}
        setModal={setModal}
      />
    </>
  );
}

KpiUpdates.contextTypes = {
  t: PropTypes.func.isRequired
};

KpiUpdates.propTypes = {
  history: PropTypes.object.isRequired,
  csoDisplay: PropTypes.bool
};
