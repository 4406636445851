import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import * as LoginActions from "../../redux/actions/LoginActions";
import logo from "assets/img/logo.png";
import landingPageImage from "assets/img/landing_page_image.png";

const useStyles = makeStyles(styles);

export default function PasswordResetView(props) {
  const token = props.token ? props.token : null;
  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [payload, setPayload] = useState({
    token: token ? token : null,
    password: "",
    password2: "",
  });
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");

  useEffect(() => {
    setCardAnimation("");
  }, []);

  const handleResetPassword = () => {
    setSaving(true);
    LoginActions.resetPassword(
      payload.token,
      payload.password,
      payload.password2,
      (resp) => {
        successAlert();
      },
      (error) => {
        setSaving(false);
        let errorMessage =
          error.message +
          (error.response?.data?.password
            ? " " + error.response.data.password
            : "") +
          (error.response?.data?.password2
            ? " " + error.response.data.password2
            : "");
        errorAlert(errorMessage);
      }
    );
  };

  const successAlert = () => {
    swal({
      title: "Well Done!!",
      text: "Your Password has successfully been reset.",
      icon: "success",
      buttons: {
        confirm: {
          text: "OK",
          className: classes.button + " " + classes.success,
        },
      },
    }).then(() => {
      if (props.lostFocus) {
        props.lostFocus();
      }
      window.location.href = `/admin/login-page`;
    });
  };

  const errorAlert = (message) => {
    swal({
      title: "Error",
      text: message,
      icon: "error",
      buttons: {
        confirm: {
          text: "OK",
          className: classes.button + " " + classes.success,
        },
      },
    });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <GridContainer justifyContent="center" alignItems="center">
      <div className={classes.loginSection}>
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem xs={12} sm={12} md={12} className={classes.textCenter}>
            <img src={logo} alt="logo" className={classes.logo} />
            <h3 className={classes.title}>ILM 3.0</h3>
            <small className={classes.subtitle}>
              Inclusive Labor Monitoring System
            </small>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <center>
              <form className={classes.formStyle}>
                <Card
                  login
                  className={`${classes[cardAnimaton]} ${classes.card}`}
                >
                  <CardHeader className={`${classes.textCenter}`} color="rose">
                    <h4 className={classes.cardTitle}>Reset Password</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="New Password"
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        type: showPassword ? "text" : "password",
                        autoComplete: "off",
                        onChange: (e) => {
                          setPayload({
                            ...payload,
                            password: e.target.value,
                          });
                        },
                      }}
                    />
                    <CustomInput
                      labelText="Re-Enter Password to Verify"
                      id="password2"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        type: showPassword ? "text" : "password",
                        autoComplete: "off",
                        onChange: (e) => {
                          setPayload({
                            ...payload,
                            password2: e.target.value,
                          });
                        },
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showPassword}
                          onChange={toggleShowPassword}
                          color="primary"
                        />
                      }
                      label="Show Password"
                    />
                  </CardBody>
                  <CardFooter
                    className={`${classes.justifyContentCenter} ${classes.cardFooter}`}
                    style={{ flexDirection: "row" }}
                  >
                    <Button
                      disabled={saving}
                      className={`${classes.customButtonClass} ${classes.letsGoButton}`}
                      color="primary"
                      onClick={handleResetPassword}
                    >
                      Update Password
                    </Button>
                  </CardFooter>
                </Card>
              </form>
            </center>
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.imageSection}>
        <div className={classes.imageOverlay}></div>
        <img
          src={landingPageImage}
          alt="Landing"
          className={classes.heroImage}
        />
      </div>
    </GridContainer>
  );
}
