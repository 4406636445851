import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Email from "@material-ui/icons/Email";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import * as LoginActions from "../../redux/actions/LoginActions";
import logo from "assets/img/logo.png";
import landingPageImage from "assets/img/landing_page_image.png";

const useStyles = makeStyles(styles);

export default function LoginPage(props, context) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  React.useEffect(() => {
    setCardAnimation("");
  }, []);

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = () => {
    LoginActions.login({
      username: email,
      password: password,
    });
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const classes = useStyles();

  return (
    <GridContainer justifyContent="center" alignItems="center">
      <div className={classes.loginSection}>
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem xs={12} sm={12} md={12} className={classes.textCenter}>
            <img src={logo} alt="logo" className={classes.logo} />
            <h3 className={classes.title}>ILM 3.0</h3>
            <small className={classes.subtitle}>
              Inclusive Labor Monitoring System
            </small>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <center>
              <form className={classes.formStyle}>
                <Card
                  login
                  className={`${classes[cardAnimaton]} ${classes.card}`}
                >
                  <CardHeader className={`${classes.textCenter}`} color="rose">
                    <h4 className={classes.cardTitle}>{context.t("Log in")}</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText={context.t("Email...")}
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        onChange: handleChangeEmail,
                      }}
                    />
                    <CustomInput
                      labelText={context.t("Password")}
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        type: showPassword ? "text" : "password",
                        autoComplete: "off",
                        onChange: handleChangePassword,
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showPassword}
                          onChange={toggleShowPassword}
                          color="primary"
                        />
                      }
                      label={context.t("Show Password")}
                    />
                  </CardBody>
                  <CardFooter
                    className={`${classes.justifyContentCenter} ${classes.cardFooter}`}
                    style={{ flexDirection: "row" }}
                  >
                    <Button
                      simple
                      size="lg"
                      className={`${classes.customButtonClass} ${classes.letsGoButton}`}
                      color="rose"
                      onClick={handleLogin}
                    >
                      {context.t("Let's Go")}
                    </Button>
                    <a
                      href="/auth/reset-password"
                      className={classes.forgotPasswordLink}
                      style={{ marginLeft: "10px" }}
                    >
                      <small>
                        <i>{context.t("Forgot Password?")}</i>
                      </small>
                    </a>
                  </CardFooter>
                </Card>
              </form>
            </center>
          </GridItem>
        </GridContainer>
      </div>
      <div className={classes.imageSection}>
        <div className={classes.imageOverlay}></div>
        <img
          src={landingPageImage}
          alt="Landing"
          className={classes.heroImage}
        />
      </div>
    </GridContainer>
  );
}

LoginPage.contextTypes = {
  t: PropTypes.func.isRequired,
};
