import SuppliersDropdown from "components/ilmdb/SuppliersDropdown.js";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import GridItem from "components/Grid/GridItem.js";
import FormControl from "@material-ui/core/FormControl";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import Checkbox from "@material-ui/core/Checkbox";
import CardText from "components/Card/CardText";
import GridContainer from "components/Grid/GridContainer.js";
import React, { useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import PropTypes from "prop-types";
import ReferralOptionDropdown from "components/ilmdb/ReferralOptionDropdown.js";

const ACTIVE_DAYS_TO_FILTER_MAPPER = {
  "0-15": { days_open_min: 0, days_open_max: 15 },
  "16-30": { days_open_min: 16, days_open_max: 30 },
  "31-45": { days_open_min: 31, days_open_max: 45 },
  "46-60": { days_open_min: 46, days_open_max: 60 },
  "61-90": { days_open_min: 61, days_open_max: 90 },
  "90+": { days_open_min: 90, days_open_max: null }
};

export default function SupplierKpiFilters(
  { params, setParams, cssclasses, csoDisplay },
  context
) {
  const [days, setDays] = useState("");
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const handleDaysChange = (event, option) => {
    setDays(option || "");

    setParams({
      ...params,
      ...(option
        ? ACTIVE_DAYS_TO_FILTER_MAPPER[option.value]
        : { days_open_min: null, days_open_max: null })
    });
  };

  const ACTIVE_DAYS_OPTIONS = [
    { label: context.t("less than 15 days"), value: "0-15" },
    { label: context.t("16-30"), value: "16-30" },
    { label: context.t("31-45"), value: "31-45" },
    { label: context.t("46-60"), value: "46-60" },
    { label: context.t("61-90"), value: "61-90" },
    { label: context.t("90+"), value: "90+" }
  ];

  return (
    <>
      {csoDisplay === true && (
        <GridItem sm={6} lg={6}>
          <ReferralOptionDropdown
            multipleselect={true}
            value={params.organization_id}
            onSelect={e => {
              let organizationId = e.target.value ? e.target.value : "";
              setParams({ ...params, organization_id: organizationId });
            }}
          />
        </GridItem>
      )}
      <GridItem xs={12}>
        {csoDisplay !== true && (
          <Card style={{ marginTop: 0 }}>
            <CardBody>
              <GridContainer justifyContent="center">
                <GridItem xs={6} sm={4} lg={4} xl={4}>
                  <SuppliersDropdown
                    label={context.t("Suppliers")}
                    multipleselect={true}
                    value={selectedSuppliers}
                    onSelect={supplier => {
                      setSelectedSuppliers(supplier);
                      setParams({ ...params, suppliers: supplier.join(",") });
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={2} lg={2} xl={1}>
                  <InputLabel className={cssclasses.label}>
                    {context.t("Between")}
                  </InputLabel>
                  <FormControl fullWidth>
                    <Datetime
                      locale="en"
                      value={params.opened_at_after}
                      timeFormat={false}
                      inputProps={{ placeholder: context.t("Start") }}
                      onChange={date =>
                        typeof date === "object" &&
                        setParams({
                          ...params,
                          opened_at_after: date.format("YYYY-MM-DD")
                        })
                      }
                      dateFormat="YYYY-MM"
                      closeOnSelect={true}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={6} sm={2} lg={2} xl={1}>
                  <InputLabel className={cssclasses.label}>
                    {context.t("And")}
                  </InputLabel>
                  <FormControl fullWidth>
                    <Datetime
                      locale="en"
                      value={params.opened_at_before}
                      timeFormat={false}
                      inputProps={{ placeholder: context.t("End") }}
                      onChange={date =>
                        typeof date === "object" &&
                        setParams({
                          ...params,
                          opened_at_before: date.format("YYYY-MM-DD")
                        })
                      }
                      dateFormat="YYYY-MM"
                      closeOnSelect={true}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={2} lg={2} xl={2}>
                  <FormControl>
                    <Button
                      color="rose"
                      onClick={() => {
                        setParams({
                          ...params,
                          suppliers: null,
                          opened_at_after: null,
                          opened_at_before: null,
                          days_open_min: null,
                          days_open_max: null,
                          show_closed: false
                        });
                        setSelectedSuppliers([]);
                        setDays("");
                      }}
                    >
                      {context.t("Reset all")}
                    </Button>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        )}
      </GridItem>

      <GridItem xs={12}>
        <Card style={{ marginTop: 0 }}>
          <CardText style={{ color: "white" }}>
            {context.t("Filter by Days Active")}
          </CardText>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="combo-box-demo"
                    options={ACTIVE_DAYS_OPTIONS}
                    onChange={(event, option) =>
                      handleDaysChange(event, option)
                    }
                    getOptionLabel={option => option.label || ""}
                    value={days}
                    renderInput={activeDayOption => (
                      <TextField
                        {...activeDayOption}
                        label={context.t("Active Days")}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>

      <GridContainer justifyContent="flex-end">
        <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              checked={params.show_closed}
              onClick={() => {
                setParams({
                  ...params,
                  show_closed: !params.show_closed,
                  ordering: !params.show_closed ? "-closed_at" : "-opened_at"
                });
              }}
              checkedIcon={<Check className={cssclasses.checkedIcon} />}
              icon={<Check className={cssclasses.uncheckedIcon} />}
              cssclasses={{
                checked: cssclasses.checked,
                root: cssclasses.checkRoot
              }}
            />
          }
          label={
            <div style={{ marginLeft: "8px" }}>{context.t("Show Closed")}</div>
          }
        />
      </GridContainer>
    </>
  );
}

SupplierKpiFilters.contextTypes = {
  t: PropTypes.func.isRequired
};

SupplierKpiFilters.propTypes = {
  params: PropTypes.object.isRequired,
  setParams: PropTypes.func.isRequired,
  cssclasses: PropTypes.object.isRequired,
  csoDisplay: PropTypes.bool.isRequired
};
