import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import swal from "sweetalert";
import { Dialog, DialogTitle, DialogActions } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomTabs from "../CustomTabs/CustomTabs";
import Introduction from "./Tabs/Introduction";
import InformationCollection from "./Tabs/InformationCollection";
import SafeguardingAffectedWorkers from "./Tabs/SafeguardingAffectedWorkers";
import EnsuringAnEthicalInvestigation from "./Tabs/EnsuringAnEthicalInvestigation";
import CommunicatingWithTheWorkers from "./Tabs/CommunicatingWithTheWorkers";
import MonitoringAndCommunication from "./Tabs/MonitoringAndCommunication";
import Trainings from "./Tabs/Trainings";
import PreviewGrievance from "./PreviewGrievance";

const GrievanceForm = (props) => {
  const context = props.context;
  const kpi_id = props.kpi_id;
  const issara_trainings = [
    "Ethical Recruitment 101 Introductory modules",
    "Ethical Recruitment Advanced training 201",
    "Business and Human Rights & HRDD: Going Beyond Audit",
    "Ethical Grievance Mechanism 101",
    "Ethical Grievance Mechanism 201",
    "Line Supervisors Training",
    "Interpreters Training",
  ];

  const [currentTab, setCurrentTab] = useState(0);

  const [openModal, setOpenModal] = useState(false);

  const [showDialog, setShowDialog] = useState(false);

  const [showMessage, setShowMessage] = useState(false);

  const [showUnmount, setShowUnmount] = useState(false);

  const [grievanceData, setGrievanceData] = useState({
    id: null,

    // Tab 2
    issara_context: "",
    check_with_hr: "",
    repeat_issue: "",
    information_collection_notes: {
      value: "",
    },

    // Tab 3
    zero_tolerance: "",
    reviewed_individual: "",
    involved_in_grievance: "",
    received_the_training: "",
    has_trained_impartial: {
      value: "",
      description: "",
    },
    safeguarding_notes: {
      value: "",
    },

    // Tab 4
    ethical_reviewed: {
      value: "",
      description: "",
    },
    professional_ensured: {
      value: "",
      description: "",
    },
    impartial_ensured: {
      value: "",
      description: "",
    },
    inclusive_and_accessible: {
      value: "",
      description: "",
    },
    timely_and_effective: {
      value: "",
      description: "",
    },
    ethical_investigation_notes: {
      value: "",
    },

    // Tab 5
    investigation_their_wellbeing: {
      value: "",
      description: "",
    },
    need_help_with_complex_consequences: null,
    communication_notes: {
      value: "",
    },

    // Tab 6
    remediation: {
      description: "",
    },
    system_strengthening: {
      description: "",
    },

    trainings: [],
  });

  const tabsRef = useRef(null);

  const prevData = useRef();

  const firstMount = useRef(true);

  const isDataChanged = useRef(true);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/supplier-kpis/${kpi_id}/grievance/`
      )
      .then((response) => {
        if (response.data.length > 0) {
          const response_data = response.data[0];
          setGrievanceData(response_data);
          prevData.current = response_data;
          console.log(response_data);
          return;
        }
        prevData.current = grievanceData;
      })
      .catch((err) => {
        console.log(
          "There was an error retrieving the grievance form data: ",
          err
        );
      });
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    document.addEventListener("mousedown", handleUnmount);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.addEventListener("mousedown", handleUnmount);
    };
  }, []);

  useEffect(() => {
    if (firstMount.current) {
      firstMount.current = false;
      return;
    }
    isDataChanged.current = prevData.current === grievanceData;
  }, [grievanceData]);

  const handleUnmount = (event) => {
    if (tabsRef.current && !tabsRef.current.contains(event.target)) {
      if (!isDataChanged.current) {
        setShowUnmount(true);
      }
    }
  };

  const handleSubmit = (isSubmit, send_email) => {
    if (isSubmit) {
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/supplier-kpis/${kpi_id}/grievance/${grievanceData.id}/`,
          {
            ...grievanceData,
            is_draft: false,
            send_email: send_email,
          }
        )
        .then((response) => {
          setGrievanceData(response.data);
          prevData.current = response.data;
          setShowDialog(false);
          swal({
            text: `Supplier grievance for case ${kpi_id} has been submitted successfully!`,
            icon: "success",
          }).then(() => {
            setOpenModal(false);
            setCurrentTab(0);
            tabsRef.current.scrollIntoView({ behaviour: "smooth" });
          });
        });
      return;
    }
    setShowDialog(true);
  };

  const handleDataChange = (updatedData) => {
    setGrievanceData((prevData) => ({ ...prevData, ...updatedData }));
  };

  const handleOnClick = (next = true) => {
    if (currentTab !== 0) {
      if (grievanceData.id === null) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/supplier-kpis/${kpi_id}/grievance/`,
            {
              ...grievanceData,
              supplier_kpi: kpi_id,
            }
          )
          .then((response) => {
            setGrievanceData(response.data);
            prevData.current = response.data;
            setShowMessage(true);
            if (!next) {
              setShowUnmount(false);
              return;
            }
            nextTab();
            setTimeout(() => {
              setShowMessage(false);
            }, 5000);
          });
        return;
      }
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}/supplier-kpis/${kpi_id}/grievance/${grievanceData.id}/`,
          {
            ...grievanceData,
            is_draft: true,
          }
        )
        .then((response) => {
          setGrievanceData(response.data);
          prevData.current = response.data;
          let tab_size =
            grievanceData.list_of_text_fields?.remediations?.length > 0 ? 5 : 6;
          if (currentTab !== tab_size) {
            setShowMessage(true);
            if (!next) {
              setShowUnmount(false);
              return;
            }
            nextTab();
            setTimeout(() => {
              setShowMessage(false);
            }, 5000);
          }
        })
        .catch((err) => {
          swal({
            text: `Error saving the grievance data as draft!`,
            icon: "error",
          });
        });
      return;
    }
    if (!next) {
      setShowUnmount(false);
      return;
    }
    nextTab();
  };

  const nextTab = () => {
    let tab_size =
      grievanceData.list_of_text_fields?.remediations?.length > 0 ? 6 : 7;
    setCurrentTab((prevIndex) => (prevIndex + 1) % tab_size);
    if (tabsRef.current) {
      tabsRef.current.scrollIntoView({ behaviour: "smooth" });
    }
  };

  const handlePreview = () => {
    handleOnClick();
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <div ref={tabsRef}>
      <GridItem xs={12}>
        <CustomTabs
          headerColor="rose"
          value={currentTab}
          handleChange={(e, value) => setCurrentTab(value)}
          grievance={true}
          tabs={[
            {
              tabName: context.t(
                "1. Introduction: Interactive Ethical Grievance Management Tool"
              ),
              tabContent: (
                <Introduction handleOnClick={handleOnClick} context={context} />
              ),
            },
            {
              tabName: context.t("2. Information collection"),
              tabContent: (
                <InformationCollection
                  context={context}
                  data={grievanceData}
                  onDataChange={handleDataChange}
                  handleOnClick={handleOnClick}
                />
              ),
            },
            {
              tabName: context.t("3. Safeguarding affected workers"),
              tabContent: (
                <SafeguardingAffectedWorkers
                  context={context}
                  data={grievanceData}
                  onDataChange={handleDataChange}
                  handleOnClick={handleOnClick}
                />
              ),
            },
            {
              tabName: context.t("4. Ensuring an ethical investigation"),
              tabContent: (
                <EnsuringAnEthicalInvestigation
                  context={context}
                  data={grievanceData}
                  onDataChange={handleDataChange}
                  handleOnClick={handleOnClick}
                />
              ),
            },
            {
              tabName: context.t(
                "5. Communicating with the workers reporting issues"
              ),
              tabContent: (
                <CommunicatingWithTheWorkers
                  context={context}
                  data={grievanceData}
                  onDataChange={handleDataChange}
                  handleOnClick={handleOnClick}
                />
              ),
            },
            {
              tabName: context.t(
                "6. Monitoring and communicating finds more broadly"
              ),
              tabContent: (
                <MonitoringAndCommunication
                  context={context}
                  data={grievanceData}
                  onDataChange={handleDataChange}
                  handleOnClick={handleOnClick}
                  handlePreview={handlePreview}
                />
              ),
            },
            {
              tabName: context.t("7. Trainings"),
              tabContent: (
                <Trainings
                  context={context}
                  data={grievanceData}
                  issara_trainings={issara_trainings}
                  onDataChange={handleDataChange}
                  handlePreview={handlePreview}
                />
              ),
            },
          ].slice(
            0,
            grievanceData.list_of_text_fields?.remediations?.length > 0 ? 6 : 7
          )}
        />

        {/* Preview */}
        <PreviewGrievance
          context={context}
          data={grievanceData}
          open={openModal}
          handleModalClose={handleModalClose}
          handleSubmit={handleSubmit}
        />

        {/* Confirm Dialog */}
        <Dialog open={showDialog}>
          <DialogTitle>
            {context.t(
              "Would you like assistance from Issara Institute to follow up on ethical grievance processes?"
            )}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                handleSubmit(true, true);
              }}
              color="rose"
              autoFocus
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                handleSubmit(true, false);
              }}
              autoFocus
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        {/* Component Unmount state */}
        <Dialog open={showUnmount}>
          <DialogTitle>
            {context.t(
              "Would you like to save the grievance form as a draft before leaving?"
            )}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => {
                handleOnClick(false);
              }}
              color="rose"
              autoFocus
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                setShowUnmount(false);
              }}
              autoFocus
            >
              No
            </Button>
          </DialogActions>
        </Dialog>

        {/* Success alert */}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={showMessage}
          autoHideDuration={5000}
        >
          <Alert severity="success">Draft saved successfully.</Alert>
        </Snackbar>
      </GridItem>
    </div>
  );
};

GrievanceForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default GrievanceForm;
