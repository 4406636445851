import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { makeStyles } from "@material-ui/core/styles";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footer/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import dashRoutes from "routes.js";
import styles from "../assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

const useStyles = makeStyles(styles);

export default function Admin(props, context) {
  const { ...rest } = props;
  const preferencesMenuImage = localStorage.getItem("preferencesMenuImage")
    ? localStorage.getItem("preferencesMenuImage")
    : require("assets/img/sidebar-1.jpg");
  const preferencesMenuColor = localStorage.getItem("preferencesMenuColor")
    ? localStorage.getItem("preferencesMenuColor")
    : "blue";
  const preferencesMenuBgColor = localStorage.getItem("preferencesMenuBgColor")
    ? localStorage.getItem("preferencesMenuBgColor")
    : "white";
  const preferencesMenuMiniActive = localStorage.getItem(
    "preferencesMenuMiniActive"
  )
    ? JSON.parse(localStorage.getItem("preferencesMenuMiniActive"))
    : false;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(preferencesMenuMiniActive);
  const [image, setImage] = React.useState(preferencesMenuImage);
  const [color, setColor] = React.useState(preferencesMenuColor);
  const [bgColor, setBgColor] = React.useState(preferencesMenuBgColor);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(require("assets/img/logo-white.png"));
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
    });
  const mainPanel = React.createRef();
  const handleImageClick = (image) => {
    setImage(image);
    localStorage.setItem("preferencesMenuImage", image);
  };
  const handleColorClick = (color) => {
    setColor(color);
    localStorage.setItem("preferencesMenuColor", color);
  };
  const handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.png"));
        break;
      default:
        setLogo(require("assets/img/logo-white.png"));
        break;
    }
    setBgColor(bgColor);
    localStorage.setItem("preferencesMenuBgColor", bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getActiveRoute = (routes) => {
    let activeRoute = "ILM";
    for (const route of routes) {
      if (route.collapse) {
        const collapseActiveRoute = getActiveRoute(route.views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      }
      if (window.location.href.indexOf(route.layout + route.path) !== -1) {
        return route.title || route.name;
      }
    }
    return activeRoute;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    const active = !miniActive;
    setMiniActive(active);
    localStorage.setItem("preferencesMenuMiniActive", active);
  };
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={dashRoutes.getDefaultRoutes(context)}
        logoText={context.t("ILM 3.0")}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(dashRoutes.getDefaultRoutes(context))}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(dashRoutes.getDefaultRoutes(context))}
                <Redirect from="/admin" to={dashRoutes.getDefaultRedirect()} />
              </Switch>
            </div>
          </div>
        }

        <AdminFooter fluid />

        <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          handleBgColorClick={handleBgColorClick}
          color={color}
          bgColor={bgColor}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
        />
      </div>
    </div>
  );
}

Admin.contextTypes = {
  t: PropTypes.func.isRequired,
};
