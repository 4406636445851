"use client";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import CustomInput from "components/CustomInput/CustomInput.js";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1, 0)
  },
  dialogContent: {
    paddingTop: theme.spacing(2)
  }
}));

function EditDocumentDialog({ document, open, onClose, onSave }) {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    size: "",
    type: "",
    permission: "",
    description: "",
    created_at: ""
  });

  useEffect(() => {
    if (document) {
      setFormData({
        id: document.id,
        name: document.name,
        size: document.size,
        type: document.type,
        permission: document.permission,
        description: document.description,
        created_at: document.created_at
      });
    }
  }, [document]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSave(formData);
  };

  if (!document) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="edit-document-dialog-title"
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="edit-document-dialog-title">Edit Document</DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Document Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="File Size"
                name="size"
                value={formData.size}
                onChange={handleChange}
                variant="outlined"
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Created At"
                name="created_at"
                value={formData.created_at}
                onChange={handleChange}
                variant="outlined"
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">Permission</FormLabel>
                <RadioGroup
                  name="permission"
                  value={formData.permission}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value="Both"
                    control={<Radio />}
                    label="Both"
                  />
                  <FormControlLabel
                    value="SP only"
                    control={<Radio />}
                    label="SP only"
                  />
                  <FormControlLabel
                    value="Supplier Only"
                    control={<Radio />}
                    label="Supplier Only"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle2" className={classes.label}>
                Note:
              </Typography>
              <CustomInput
                isTextArea={true}
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: e => {
                    console.log(e.target.value, e.target.name);
                    handleChange(e);
                  },
                  name: "description",
                  label: "Note"
                }}
                value={formData.description}
                labelText="Note"
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" color="primary" variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default EditDocumentDialog;

EditDocumentDialog.propTypes = {
  document: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func
};
