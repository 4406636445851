import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function AdminFooter(props, context) {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <div>
      <center>
        &copy; {currentYear} &nbsp;
        <a
          href="https://www.issarainstitute.org/"
          target="__blank"
          className={classes.link}
        >
          {context.t("Issara Institute")}.
        </a>
        {context.t("All rights reserved. Version 3.0.0.")}
      </center>
      <br />
    </div>
  );
}

AdminFooter.contextTypes = {
  t: PropTypes.func.isRequired,
};
