import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";
import dashRoutes from "routes.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
// import register from "assets/img/register.jpeg";
// import login from "assets/img/landing_page_image.png";
// import lock from "assets/img/lock.jpeg";
// import error from "assets/img/clint-mckoy.jpg";
// import pricing from "assets/img/bg-pricing.jpeg";

const useStyles = makeStyles(styles);

export default function Auth(props, context) {
  const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  // const getBgImage = () => {
  //   const path = window.location.pathname;
  //   if (path.includes("/auth/register-page")) return register;
  //   if (
  //     path.includes("/auth/login-page") ||
  //     path.includes("/auth/enter_code") ||
  //     path.includes("/auth/reset-password")
  //   )
  //     return login;
  //   if (path.includes("/auth/pricing-page")) return pricing;
  //   if (path.includes("/auth/lock-screen-page")) return lock;
  //   if (path.includes("/auth/error-page")) return error;
  // };

  const getActiveRoute = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        const collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== "ILM") return collapseActiveRoute;
      } else {
        if (window.location.href.includes(routes[i].layout + routes[i].path)) {
          return routes[i].name;
        }
      }
    }
    return "ILM";
  };

  return (
    <div>
      {/* <AuthNavbar
        brandText={getActiveRoute(dashRoutes.getDefaultRoutes(context))}
        {...rest}
      /> */}
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          // style={{ backgroundImage: "url(" + getBgImage() + ")" }}
        >
          <Switch>
            {getRoutes(dashRoutes.getDefaultRoutes(context))}
            <Redirect from="/auth" to="/auth/login-page" />
          </Switch>
          <Footer white />
        </div>
      </div>
    </div>
  );
}

Auth.contextTypes = {
  t: PropTypes.func.isRequired,
};
