import React, { useEffect, useState, useRef } from "react";
import { setLanguage } from "redux-i18n";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import loginStore from "redux/stores/LoginStore";
import LanguageChangeDialog from "./LanguageChangeDialog";
import Person from "@material-ui/icons/Person";
import Button from "components/CustomButtons/Button.js";
import * as LoginActions from "../../redux/actions/LoginActions";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import customStyles from "./AdminNavbarLinks.module.css";
import { updateMomentLocale } from "languages/i18n/utilities/utilities";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);
const us_flag = require("assets/img/flags/US.png");
const ne_flag = require("assets/img/flags/NE.png");
const th_flag = require("assets/img/flags/TH.png");
const mm_flag = require("assets/img/flags/MM.png");
const ja_flag = require("assets/img/flags/JA.png");
const kh_flag = require("assets/img/flags/KH.png");
const id_flag = require("assets/img/flags/ID.png");

const flagMap = {
  en: { src: us_flag, alt: "US Flag", label: "English" },
  ne: { src: ne_flag, alt: "Nepal Flag", label: "Nepali" },
  mm: { src: mm_flag, alt: "Myanmar Flag", label: "Burmese" },
  th: { src: th_flag, alt: "Thailand Flag", label: "Thai" },
  ja: { src: ja_flag, alt: "Japan Flag", label: "Japanese" },
  kh: { src: kh_flag, alt: "Cambodia Flag", label: "Khmer" },
  id: { src: id_flag, alt: "Indonesia Flag", label: "Indonesian" },
};

const getAvailableLanguages = () => {
  if (loginStore.isGlobalPartner()) {
    return ["en", "ja"];
  } else if (loginStore.isSupplier()) {
    return ["en", "th"];
  } else if (loginStore.isCSO()) {
    return ["ne", "mm", "kh", "id", "th"];
  } else {
    return [];
  }
};

export default function HeaderLinks(props, context) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dialogState, setDialogState] = useState(false);
  const lang = useSelector((state) => state.i18nState.lang);
  const [tochangeLang, setTochangeLang] = useState(null);
  const [alert, setAlert] = useState(null);

  const [openProfile, setOpenProfile] = useState(null);
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleLogout = () => {
    LoginActions.logout();
  };

  useEffect(() => {
    if (loginStore.isSupplier()) {
      updateMomentLocale(
        localStorage.getItem("supplier_lang")
          ? localStorage.getItem("supplier_lang")
          : "en"
      );
    } else if (loginStore.isCSO()) {
      updateMomentLocale(
        localStorage.getItem("cso_lang")
          ? localStorage.getItem("cso_lang")
          : "en"
      );
    } else if (loginStore.isGlobalPartner()) {
      updateMomentLocale(
        localStorage.getItem("global_partner_lang")
          ? localStorage.getItem("global_partner_lang")
          : "en"
      );
    } else {
      updateMomentLocale("en");
    }
  }, []);

  const classes = useStyles();
  const { rtlActive } = props;

  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });

  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });

  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  function handleLanguageChange(lang) {
    setDialogState(true);
    setTochangeLang(lang);
  }

  const languageTextRenderer = (lang) => {
    const { src, alt, label } = flagMap[lang] || flagMap["en"];
    return (
      <>
        <img src={src} alt={alt} style={{ height: "14px" }} />
        &nbsp;&nbsp;&nbsp;&nbsp;{context.t(label)}
      </>
    );
  };

  const languageConfirmation = (lang) => {
    if (lang) {
      updateMomentLocale(lang);
      dispatch(setLanguage(lang));
      if (loginStore.isSupplier()) {
        localStorage.setItem("supplier_lang", lang);
      } else if (loginStore.isCSO()) {
        localStorage.setItem("cso_lang", lang);
      } else if (loginStore.isGlobalPartner()) {
        localStorage.setItem("global_partner_lang", lang);
      }
    }
    setOpen(false);
    setDialogState(false);
    window.location.href = window.location.href;
  };

  const availableLanguages = getAvailableLanguages();

  return alert ? (
    <div>{alert}</div>
  ) : (
    <div className={wrapper}>
      <LanguageChangeDialog
        tochangeLang={tochangeLang}
        fullToChangeLang={languageTextRenderer(tochangeLang)}
        confirmation={(lang) => languageConfirmation(lang)}
        dialogState={dialogState}
      />
      <div className={managerClasses}>
        {availableLanguages.length > 0 ? (
          <Button
            variant="contained"
            color="primary"
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            style={{ width: "160px" }}
            className={classNames({
              [classes.fullWidthButton]: true,
            })}
          >
            {languageTextRenderer(lang)}
          </Button>
        ) : (
          ""
        )}

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !open,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: "window",
            },
          }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList role="menu" onKeyDown={handleListKeyDown}>
                    {availableLanguages.map((key) => (
                      <MenuItem
                        key={key}
                        onClick={handleLanguageChange.bind(this, key)}
                      >
                        <ListItemIcon className={customStyles.flags}>
                          <img
                            src={flagMap[key].src}
                            alt={flagMap[key].alt}
                            style={{ height: "14px" }}
                          />
                        </ListItemIcon>
                        <Typography variant="inherit">
                          {context.t(flagMap[key].label)}
                        </Typography>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Button
          color="transparent"
          aria-label={context.t("Person")}
          justIcon
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: "",
          }}
          style={{ width: "auto" }} // Add this line to ensure the button does not take full width
        >
          <Person className={classes.headerLinksSvg + " " + classes.links} />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {context.t("Profile")}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: "window",
            },
          }}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    {loginStore.isIssaraManagement() === false && (
                      <React.Fragment>
                        <MenuItem
                          disabled={
                            !loginStore.isSupplier() &&
                            !loginStore.isGlobalPartner()
                          }
                          onClick={(event) => {
                            if (loginStore.isSupplier() === true) {
                              history.push("/admin/supplier-welcome-page");
                            } else if (loginStore.isGlobalPartner() === true) {
                              history.push("/admin/partner-welcome-page");
                            }
                            handleCloseProfile();
                          }}
                          className={dropdownItem + props.sidebarWrapper}
                        >
                          <div className={classes.dropdownItem}>
                            {context.t("What’s new")}
                          </div>
                        </MenuItem>
                        <MenuItem
                          onClick={() => console.log("Account settings")}
                          className={dropdownItem + props.sidebarWrapper}
                          disabled={true}
                        >
                          <div className={classes.dropdownItem}>
                            {context.t("Account settings")}
                          </div>
                        </MenuItem>
                        <MenuItem
                          disabled={
                            !loginStore.isSupplier() &&
                            !loginStore.isGlobalPartner()
                          }
                          onClick={(event) => {
                            if (loginStore.isSupplier() === true) {
                              history.push("/admin/supplier-legal-disclaimer");
                            } else if (loginStore.isGlobalPartner() === true) {
                              history.push("/admin/partner-legal-disclaimer");
                            }
                            handleCloseProfile();
                          }}
                          className={dropdownItem + props.sidebarWrapper}
                        >
                          <div className={classes.dropdownItem}>
                            {context.t("Legal")}
                          </div>
                        </MenuItem>
                      </React.Fragment>
                    )}
                    <MenuItem
                      onClick={handleLogout}
                      className={dropdownItem + props.sidebarWrapper}
                    >
                      <div className={classes.dropdownItem}>
                        {context.t("Log Out")}
                      </div>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};

HeaderLinks.contextTypes = {
  t: PropTypes.func.isRequired,
};
