import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props, context) {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <footer
      className={classes.footer}
      style={{ position: "absolute", width: "100vw" }}
    >
      <div className={classes.footerContainer}>
        <div className={classes.leftColumn}></div>
        <div className={classes.rightColumn}>
          <div className={classes.textContainer}>
            &copy; {currentYear} &nbsp;
            <a
              href="https://www.issarainstitute.org/"
              target="__blank"
              className={classes.link}
            >
              {context.t("Issara Institute")}.
            </a>
            {context.t("All rights reserved. Version 3.0.0.")}
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.contextTypes = {
  t: PropTypes.func.isRequired,
};
