import {
  cardTitle,
  whiteColor,
  grayColor,
  hexToRgb,
  container,
} from "assets/jss/material-dashboard-pro-react.js";

const enterCodePageStyle = (theme) => ({
  container: {
    ...container,
    zIndex: "4",
    height: "100vh", // Full height
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
      flexDirection: "column", // Stack elements vertically on small screens
    },
    position: "relative",
    display: "flex",
    color: whiteColor,
  },
  loginSection: {
    padding: "2%",
    width: "41%",
    backgroundColor: whiteColor, // White background
    height: "100vh", // Full height
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: 0,
    zIndex: "2",
    [theme.breakpoints.down("sm")]: {
      width: "100%", // 100% width for mobile
      height: "auto", // Adjust height for mobile
      position: "relative",
      paddingTop: "10vh", // Adjusted padding for mobile
      padding: "5%", // Add padding for mobile
    },
  },
  imageSection: {
    width: "59%",
    height: "100vh",
    position: "absolute",
    right: 0,
    overflow: "hidden", // Ensure overflow is hidden
    zIndex: 0, // Set z-index to 0
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100vh", // Full height for mobile
      position: "absolute", // Keep position absolute
      zIndex: 0, // Ensure image is behind login section
    },
  },
  imageOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(to top, black, transparent)",
    zIndex: 1,
  },
  heroImage: {
    width: "100%",
    height: "102%", // Increase height to overflow top and bottom
    objectFit: "cover",
    position: "relative",
    top: "-1%", // Shift image up by 1%
    zIndex: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "102%", // Adjust height for mobile
      top: "-1%", // Shift image up by 1% for mobile
    },
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: "0",
    transition: "opacity 0.7s ease-in-out", // Updated transition for fade-in effect
  },
  cardHeader: {
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center", // Center align text for mobile
    },
  },
  card: {
    borderRadius: "15px",
    boxShadow: `0 16px 24px 2px rgba(${hexToRgb(
      grayColor[9]
    )}, 0.14), 0 6px 30px 5px rgba(${hexToRgb(
      grayColor[9]
    )}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(grayColor[9])}, 0.2)`,
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none", // Remove box shadow for mobile
      borderRadius: "0", // Remove border radius for mobile
    },
  },
  formStyle: {
    maxWidth: "400px",
    width: "100%", // Ensure full width on small screens
  },
  button: {
    color: "rose",
    simple: true,
    size: "lg",
    block: true,
  },
});

export default enterCodePageStyle;
