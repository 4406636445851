import React, { useState, useEffect } from "react";
import swal from "sweetalert";

import { useDispatch, useSelector } from "react-redux";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// Tabs
import NewCallWizardView from "./NewCallWizardView.js";

import utils from "../../services/utils.js";

import {
  createCall,
  updateCall,
  fetchCallById,
} from "redux/actions/CallActions.js";

import PropTypes from "prop-types";

export default function InteractionCallView(props, context) {
  const dispatch = useDispatch();

  const loadedItem = useSelector(
    (state) => state.workerVoiceCaseCallsReducer.call_item
  );

  const [theState, setTheState] = useState({
    id: undefined,
    loadingData: false,
    alert: null,
    dataItem: undefined,
    isSaving: false,
    cachedUserEnteredData: undefined,
    newWizardForm: null,
  });

  const setComponentState = (newStateValueObj) => {
    let newState = Object.assign({}, theState, newStateValueObj);
    setTheState(newState);
  };

  const saveCall = (call, allData) => {
    setComponentState({ isSaving: true });

    const allDataLastUserEnteredState = Object.assign({}, allData);

    setTimeout(() => {
      if (call && call.id !== undefined) {
        dispatch(
          updateCall(
            call,
            (updatedCall) => {
              successAlert(
                context.t("Success saving the call with id:") + updatedCall.id
              );
            },
            (error) => {
              errorAlert(error, allDataLastUserEnteredState);
            }
          )
        );
      } else {
        dispatch(
          createCall(
            call,
            (newCall) => {
              if (newCall && newCall.id) {
                successAlert(
                  context.t("Success created a new call with id:") + newCall.id
                );
              } else {
                errorAlert(
                  context.t("Failed to create a new call. Missing ID."),
                  allDataLastUserEnteredState
                );
              }
            },
            (error) => {
              errorAlert(error, allDataLastUserEnteredState);
            }
          )
        );
      }
    }, -1);
  };

  const successAlert = (message) => {
    setComponentState({ isSaving: false });
    swal({
      title: context.t("Well Done!"),
      text: message || context.t("Operation completed successfully."),
      icon: "success",
      buttons: {
        confirm: {
          text: context.t("OK"),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then(() => {
      const history_return_view_id = new URLSearchParams(
        props.location.search
      ).get("history_return_view");
      let is_history_return_view =
        history_return_view_id !== null &&
        history_return_view_id !== undefined &&
        history_return_view_id !== "";

      props.history.push(
        is_history_return_view === true
          ? history_return_view_id
          : "/admin/calls"
      );
    });
  };

  function fu(errors) {
    const htmlContent = (
      <div>
        {errors
          ? errors.map((el, e) => {
              return <p style={{ color: "red" }}>{el}</p>;
            })
          : ""}
      </div>
    );
    return swal({
      text: context.t("Errors"),
      icon: "error",
      content: htmlContent,
    });
  }

  const errorAlert = (error, allData) => {
    let errors;
    try {
      errors = JSON.parse(error);
    } catch (e) {
      errors = [context.t("An unknown error occurred.")];
    }
    setComponentState({ isSaving: false });
    swal({
      title: context.t("Error!"),
      text: errors[0] || context.t("An error occurred."),
      icon: "error",
      buttons: {
        confirm: {
          text: context.t("OK"),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then(() => {
      setComponentState({
        alert: null,
        cachedUserEnteredData: allData,
        loadingData: false,
        isSaving: false,
        newWizardForm: (
          <NewCallWizardView
            key={utils.giveMeGuid()}
            saveCall={(call, allData) => {
              saveCall(call, allData);
            }}
            allDataWizardSaveState={allData}
          />
        ),
      });
    });
  };

  const idExistsUseEffectAction = (urlId) => {
    if (theState.loadingData === false && theState.dataItem === undefined) {
      dispatch(fetchCallById(urlId));
      setComponentState({
        id: urlId,
        loadingData: true,
        dataItem: undefined,
        newWizardForm: null,
      });
    } else if (theState.loadingData === true && loadedItem !== undefined) {
      setComponentState({
        dataItem: loadedItem,
        loadingData: false,
        newWizardForm: (
          <NewCallWizardView
            key={utils.giveMeGuid()}
            saveCall={(call, allData) => {
              saveCall(call, allData);
            }}
            loadedItem={loadedItem}
          />
        ),
      });
    }
  };

  useEffect(() => {
    if (theState.cachedUserEnteredData !== undefined) {
      return;
    }

    const urlId = new URLSearchParams(props.location.search).get("id");
    let isUrlIdExist = urlId !== null && urlId !== undefined && urlId !== "";

    if (isUrlIdExist === true) {
      idExistsUseEffectAction(urlId);
    } else if (theState.id !== undefined || theState.newWizardForm === null) {
      setComponentState({
        id: undefined,
        dataItem: undefined,
        loadingData: false,
        newWizardForm: (
          <NewCallWizardView
            key={utils.giveMeGuid()}
            saveCall={(call, allData) => {
              saveCall(call, allData);
            }}
            loadedItem={undefined}
          />
        ),
      });
    }
  });

  if (theState.isSaving === true) {
    return <div>{context.t("Saving Data...")}</div>;
  }

  if (theState.loadingData === true) {
    return <div>{context.t("Loading Data...")}</div>;
  }

  return (
    <GridContainer>
      {theState.alert !== null ? (
        <GridItem>{theState.alert}</GridItem>
      ) : (
        <GridItem xs={12}>{theState.newWizardForm}</GridItem>
      )}
    </GridContainer>
  );
}

InteractionCallView.contextTypes = {
  t: PropTypes.func.isRequired,
};
