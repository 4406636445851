import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

// Custom styled tooltip
const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

// Helper function to truncate strings
const truncateString = (str, maxLength) => {
  if (!str) return "";
  return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
};

// Cell components with display names
const TooltipCell = ({ value, interactive = true }) => (
  <HtmlTooltip title={value} interactive={interactive}>
    <div className="cell-overflow">{value}</div>
  </HtmlTooltip>
);
TooltipCell.displayName = "TooltipCell";

const TruncatedTooltipCell = ({ value, maxLength = 60 }) => (
  <HtmlTooltip title={value} interactive>
    <div className="cell-overflow">{truncateString(value, maxLength)}</div>
  </HtmlTooltip>
);
TruncatedTooltipCell.displayName = "TruncatedTooltipCell";

const AssignedToCell = ({ value, className }) => (
  <div className={`cell-overflow ${className}`}>{value}</div>
);
AssignedToCell.displayName = "AssignedToCell";

const IdCell = ({ value }) => <TooltipCell value={value} />;
IdCell.displayName = "IdCell";

const SupplierCell = ({ value }) => <TooltipCell value={value} />;
SupplierCell.displayName = "SupplierCell";

const CountryCell = ({ value }) => <TooltipCell value={value} />;
CountryCell.displayName = "CountryCell";

const AssignedToUserCell = ({ value, className }) => (
  <AssignedToCell value={value} className={className} />
);
AssignedToUserCell.displayName = "AssignedToUserCell";

// Create the column configuration
export const createTableColumns = ({
  t,
  onView,
  classes = {},
  showHidden = false
}) => {
  return [
    {
      Header: t("id"),
      accessor: "id",
      width: 60,
      Cell: IdCell
    },
    {
      Header: t("Supplier Kpi ID"),
      accessor: "supplier_kpi",
      width: 180,
      Cell: props => (
        <HtmlTooltip title={t(
          "This is the parent Supplier KPI ID.  This is ID in the Supplkier KPI List table above"
        )} interactive>
          <div className="cell-overflow">
            {props.value}
          </div>
        </HtmlTooltip>
      )
    },
    {
      Header: t("Supplier"),
      id: "supplier_kpi__supplier__name",
      accessor: "supplier_name",
      width: 120,
      Cell: SupplierCell
    },
    {
      Header: t("Category"),
      accessor: "category",
      id: "supplier_kpi__kpi__kpi_category",
      width: 150,
      Cell: TruncatedTooltipCell
    },
    {
      Header: t("Description"),
      id: "supplier_kpi__kpi__description",
      accessor: "description",
      width: 220,
      Cell: TruncatedTooltipCell
    },
    {
      Header: t("Level"),
      id: "supplier_kpi__kpi__level",
      accessor: "level",
      width: 60
    },
    {
      Header: t("Opened At"),
      accessor: "opened_at",
      width: 100
    },
    {
      Header: t("Closed At"),
      accessor: "closed_at",
      width: 100,
      show: showHidden
    },
    {
      Header: t("Closure Date"),
      accessor: "closure_date",
      width: 120,
      show: showHidden
    },
    {
      Header: t("Quality of the closure"),
      accessor: "closed_quality",
      width: 120,
      show: showHidden
    },
    {
      Header: t("Affected"),
      accessor: "affected_workers",
      width: 60
    },
    {
      Header: t("Status"),
      id: "status__name",
      accessor: "status_name",
      width: 90
    },
    {
      Header: "",
      sortable: false,
      filterable: false,
      accessor: "supplier_kpi",
      width: 60,
      Cell: ({ value }) => onView?.(value)
    }
  ];
};
