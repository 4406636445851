import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import loginStore from "redux/stores/LoginStore.js";
import axios from "axios";

const API_BASE_URL = `${process.env.REACT_APP_API_URL}/supplierkpiupdates/`;

export const fetchItems = createAsyncThunk(
  "supplierKpiUpdates/fetchList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(API_BASE_URL, {
        params,
        headers: loginStore.getAxiosConfigHeaders().headers
      });
      const requestParams = response.config.params;

      return { data: response.data, params: requestParams };
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching data");
    }
  }
);

export const fetchsupplierKpiUpdateById = createAsyncThunk(
  "supplierKpiUpdate/fetchById",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}${id}/`, {
        params,
        headers: loginStore.getAxiosConfigHeaders().headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching details");
    }
  }
);

const supplierKpiUpdateUpdateSlice = createSlice({
  name: "supplierKpiUpdateUpdate",
  initialState: {
    list: [],
    selected: null,
    calls: [],
    destinationRecruiters: [],
    sourceRecruiters: [],
    loading: false,
    error: null,
    total: 0,
    requestParams: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchItems.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchItems.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.data.results;
        state.total = action.payload.data.count;
        state.requestParams = action.payload.params;
      })
      .addCase(fetchItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchsupplierKpiUpdateById.fulfilled, (state, action) => {
        state.selected = action.payload;
      });
  }
});

export default supplierKpiUpdateUpdateSlice.reducer;
