import {
  container,
  cardTitle,
  whiteColor,
  grayColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react.js";

const loginPageStyle = (theme) => ({
  issaraTextColor: {
    color: "#FFC107",
  },
  container: {
    ...container,
    zIndex: "4",
    height: "100vh", // Full height
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px",
      flexDirection: "column", // Stack elements vertically on small screens
    },
    position: "relative",
    display: "flex",
    color: whiteColor,
  },
  imageSection: {
    width: "59%",
    height: "100vh",
    position: "absolute",
    right: 0,
    overflow: "hidden", // Ensure overflow is hidden
    zIndex: 0, // Set z-index to 0
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100vh", // Full height for mobile
      position: "absolute", // Keep position absolute
      zIndex: 0, // Ensure image is behind login section
    },
  },
  imageOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(to top, black, transparent)",
    zIndex: 1,
  },
  heroImage: {
    width: "100%",
    height: "102%", // Increase height to overflow top and bottom
    objectFit: "cover",
    position: "relative",
    top: "-1%", // Shift image up by 1%
    zIndex: 0,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "102%", // Adjust height for mobile
      top: "-1%", // Shift image up by 1% for mobile
    },
  },
  loginSection: {
    padding: "2%",
    width: "41%",
    backgroundColor: whiteColor, // White background
    height: "100vh", // Full height
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: 0,
    zIndex: "2",
    [theme.breakpoints.down("sm")]: {
      width: "100%", // Ensure full width for mobile
      height: "100vh", // Full height for mobile
      position: "relative",
      paddingTop: "10vh", // Adjusted padding for mobile
      padding: "5%", // Add padding for mobile
      zIndex: 2, // Ensure login section is above image section
    },
  },
  cardTitle: {
    ...cardTitle,
    color: whiteColor,
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor,
    },
    marginLeft: "5px",
    marginRight: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%", // Full width button for mobile
      margin: "10px 0", // Add margin for mobile
    },
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem", // Adjust icon size for mobile
    },
  },
  cardHidden: {
    opacity: "0",
    transition: "opacity 0.7s ease-in-out", // Updated transition for fade-in effect
  },
  cardHeader: {
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center", // Center align text for mobile
    },
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  card: {
    borderRadius: "15px",
    boxShadow: `0 16px 24px 2px rgba(${hexToRgb(
      grayColor[9]
    )}, 0.14), 0 6px 30px 5px rgba(${hexToRgb(
      grayColor[9]
    )}, 0.12), 0 8px 10px -5px rgba(${hexToRgb(grayColor[9])}, 0.2)`,
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none", // Remove box shadow for mobile
      borderRadius: "0", // Remove border radius for mobile
    },
  },
  logo: {
    width: "81px",
    height: "81px",
  },
  title: {
    color: "teal",
  },
  subtitle: {
    color: "teal",
  },
  cardFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  letsGoButton: {
    backgroundColor: "teal", // Updated background color
    color: whiteColor + " !important", // Ensure text color is white
    padding: "12px 30px",
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "uppercase",
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)", // Added text shadow
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "#008080", // Darker teal on hover
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%", // Full width button for mobile
      margin: "10px 0", // Add margin for mobile
      order: 2, // Stack order for mobile
    },
  },
  forgotPasswordLink: {
    color: "black", // Updated text color
    marginTop: "10px",
    fontSize: "14px",
    textDecoration: "none",
    border: "1px solid white", // Outlined white style
    padding: "10px 20px", // Added padding
    borderRadius: "30px", // Added border radius
    "&:hover": {
      textDecoration: "underline",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      width: "100%", // Full width for mobile
      display: "block", // Ensure it appears as a block element
      marginTop: "10px", // Add spacing below the button
      textAlign: "center", // Center align text
    },
  },
  formStyle: {
    maxWidth: "400px",
    width: "100%",
  },
});

export default loginPageStyle;
