import React from "react";
import axios from "axios";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "components/Grid/GridItem.js";
import FormLabel from "@material-ui/core/FormLabel";
import CountryProvincesDistrictsDropdown from "components/ilmdb/CountryProvincesDistrictsDropdown.js";
import CaseCategoriesDropdown from "components/ilmdb/CaseCategoriesDropdown.js";
import ClientTypesDropdown from "components/ilmdb/ClientTypesDropdown.js";
import CallerStatusDropdown from "components/ilmdb/CallerStatusDropdown.js";
import NationalitiesDropdown from "components/ilmdb/NationalitiesDropdown.js";
import EtnicitiesDropdown from "components/ilmdb/EthnicitiesDropdown";
import Utils from "services/utils.js";
import swal from "sweetalert";
import HttpService from "services/HttpService";
import loginStore from "redux/stores/LoginStore";
import RecruitersDropdown from "components/ilmdb/RecruitersDropdown";
import PropTypes from "prop-types";
import SuppliersPaginateDropdown from "components/Async/SuppliersPaginateDropdown";

let _user = loginStore.getLoginUser();

const style = {
  infoText: {
    fontWeight: "300",
    // margin: "12px 0 16px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class RequiredCallFields extends React.Component {
  constructor(props) {
    super(props);

    // Initialize state based on props or default values
    this.state = Object.assign({}, props.allDataWizardSaveState || {}, {
      supplierDetail: {}, // Add empty object for supplierDetail
      supplier:
        props.globalEditData && props.globalEditData.supplier
          ? props.globalEditData.supplier.id
          : undefined,
      country:
        props.globalEditData && props.globalEditData.country
          ? props.globalEditData.country
          : undefined,
      province:
        props.globalEditData && props.globalEditData.province
          ? props.globalEditData.province
          : undefined,
      district:
        props.globalEditData && props.globalEditData.district
          ? props.globalEditData.district
          : undefined,
      client_nationality:
        props.globalEditData && props.globalEditData.client_nationality
          ? props.globalEditData.client_nationality.id
          : undefined,
      client_ethnicity:
        props.globalEditData && props.globalEditData.client_ethnicity
          ? props.globalEditData.client_ethnicity.id
          : undefined,
      client_status:
        props.globalEditData && props.globalEditData.client_status
          ? props.globalEditData.client_status.id
          : undefined,
      client_type:
        props.globalEditData && props.globalEditData.client_type
          ? props.globalEditData.client_type.id
          : undefined,
      case_category:
        props.globalEditData && props.globalEditData.case_category
          ? props.globalEditData.case_category.id
          : undefined,
      source_recruiter:
        props && props.globalEditData && props.globalEditData.source_recruiter
          ? props.globalEditData.source_recruiter.id
          : undefined,
    });
  }

  sendState() {
    let returnObj = Object.assign({}, this.state);
    returnObj.was_validated = this.isValidated();
    return returnObj;
  }

  isValidated = () => {
    let isValid = true;
    let errorsInState = {};

    if (Utils.isEmptyField(this.state.supplier) === true) {
      errorsInState["supplier_error"] = (
        <FormLabel error>
          {this.context.t("Supplier must be selected")}
        </FormLabel>
      );
    }

    if (Utils.isEmptyField(this.state.country) === true) {
      errorsInState["country_error"] = (
        <FormLabel error>
          {this.context.t("Country must be selected")}
        </FormLabel>
      );
    }

    if (Utils.isEmptyField(this.state.client_nationality) === true) {
      errorsInState["client_nationality_error"] = (
        <FormLabel error>
          {this.context.t("Nationality must be selected")}
        </FormLabel>
      );
    }

    if (Utils.isEmptyField(this.state.client_status) === true) {
      errorsInState["client_status_error"] = (
        <FormLabel error>
          {this.context.t("CallerStatus must be selected")}
        </FormLabel>
      );
    }

    if (Utils.isEmptyField(this.state.client_type) === true) {
      errorsInState["client_type_error"] = (
        <FormLabel error>
          {this.context.t("ClientType must be selected")}
        </FormLabel>
      );
    }

    if (Utils.isEmptyField(this.state.case_category) === true) {
      errorsInState["case_category_error"] = (
        <FormLabel error>
          {this.context.t("CaseCategory must be selected")}
        </FormLabel>
      );
    }

    if (Object.keys(errorsInState).length > 0) {
      isValid = false;
      this.setState(errorsInState);
    }

    return isValid;
  };

  fetchSupplierById = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/suppliers/${id}`
      );
      const supplierData = response?.data;
      if (supplierData) {
        this.setState({
          ...this.state,
          supplier_data: supplierData,
          supplier: id,
          country: supplierData && supplierData.country,
          province: supplierData && supplierData.province,
          district: supplierData && supplierData.district,
          supplier_error: undefined,
        });
      }
      return response.data;
    } catch (error) {
      console.error("Error fetching supplier:", error);
      throw error;
    }
  };

  handleChangeSupplier = (supplierId) => {
    if (supplierId) {
      this.fetchSupplierById(supplierId);
      HttpService.get(
        `${process.env.REACT_APP_API_URL}/suppliers/${supplierId}/supplier_has_supply_chain/`,
        (resp) => {
          if (_user && _user.groups.includes("CSO")) {
            if (resp["Supplier has supply chain"]) {
              swal({
                text: this.context.t(
                  "Please fill out the information and refer this case to Issara Institute. Thank you, have a great day!"
                ),
                icon: "info",
                buttons: true,
              });
            }
          }
        },
        (error) => {
          console.log("error", error);
        }
      );
    } else {
      console.log("Please select a supplier");
    }
  };

  render() {
    const { classes, globalEditData } = this.props;
    const valueEmpty = " ";

    return (
      <form>
        <GridItem xs={12}>
          <h4 className={classes.infoText}>
            {this.context.t("Required Info")}
          </h4>
        </GridItem>

        <GridItem>
          {this.state.supplier_error}
          <SuppliersPaginateDropdown
            isClearable={true}
            label={this.context.t("Suppliers")}
            placeholder={this.context.t("Select a supplier")}
            value={this.state.supplier}
            isSearchable={true}
            isMulti={false}
            onSelect={(supplierId) => {
              this.handleChangeSupplier(supplierId);
            }}
          />
        </GridItem>
        {_user && _user.groups.includes("CSO") ? (
          <GridItem>
            <RecruitersDropdown
              value={this.state.source_recruiter}
              label={"Source Recruiter"}
              onSelect={(recruiterId) => {
                this.setState({ source_recruiter: recruiterId });
              }}
            />
          </GridItem>
        ) : null}

        <GridItem>
          {this.state.country_error}
          <CountryProvincesDistrictsDropdown
            supplier_data={this.state.supplier_data}
            country_values={this.state.country}
            province_values={this.state.province}
            district_values={this.state.district}
            onSelect={(e) => {
              console.log("Supplier Selected:", e);
              this.setState({
                country: e.country_id,
                province: e.province_id,
                district: e.district_id,
                country_error: undefined,
              });
            }}
          />
        </GridItem>

        <GridItem>
          {this.state.client_nationality_error}
          <NationalitiesDropdown
            value={this.state.client_nationality}
            label={this.context.t("Nationality")}
            onSelect={(nationality) => {
              console.log("Nationality Selected:", nationality);
              this.setState({
                client_nationality: nationality,
                client_nationality_error: undefined,
              });
            }}
          />
        </GridItem>

        <GridItem>
          {this.state.client_ethnicity_error}
          <EtnicitiesDropdown
            values={this.state.client_ethnicity}
            onSelect={(e) => {
              console.log("Ethnicity Selected:", e);
              this.setState({
                client_ethnicity: Number.parseInt(e.target.value),
                client_ethnicity_error: undefined,
              });
            }}
          />
        </GridItem>

        <GridItem>
          {this.state.case_category_error}
          <CaseCategoriesDropdown
            values={this.state.case_category}
            onSelect={(e) => {
              console.log("CaseCateogry Selected:", e);
              this.setState({
                case_category:
                  e.target.value === undefined || e.target.value === valueEmpty
                    ? undefined
                    : Number.parseInt(e.target.value),
                case_category_error: undefined,
              });
            }}
          />
        </GridItem>

        <GridItem>
          {this.state.client_type_error}
          <ClientTypesDropdown
            values={this.state.client_type}
            onSelect={(e) => {
              console.log("ClientType Selected:", e);
              this.setState({
                client_type:
                  e.target.value === undefined || e.target.value === valueEmpty
                    ? undefined
                    : Number.parseInt(e.target.value),
                client_type_error: undefined,
              });
            }}
          />
        </GridItem>

        <GridItem>
          {this.state.client_status_error}
          <CallerStatusDropdown
            values={this.state.client_status}
            onSelect={(e) => {
              console.log("CallerStatus Selected:", e);
              this.setState({
                client_status:
                  e.target.value === undefined || e.target.value === valueEmpty
                    ? undefined
                    : Number.parseInt(e.target.value),
                client_status_error: undefined,
              });
            }}
          />
        </GridItem>
      </form>
    );
  }
}

export default withStyles(style)(RequiredCallFields);

RequiredCallFields.contextTypes = {
  t: PropTypes.func.isRequired,
};
