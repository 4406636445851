import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { fetchItems } from "redux/stores/supplierKpi/supplierKpiUpdateSlice";
import Pagination from "components/Pagination/Pagination2.js";
import ReactTable from "react-table-6";
import GridItem from "components/Grid/GridItem.js";
import FormControl from "@material-ui/core/FormControl";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { createTableColumns } from "constants/supplierKPIUpdateHistoryTableColumns";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { debounce } from "lodash";

// Custom styled tooltip
const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9"
  }
}))(Tooltip);

const FILTERABLE_KEYS = [
  "id",
  "supplier_kpi",
  "supplier_kpi__supplier__name",
  "supplier_kpi__kpi__kpi_category",
  "supplier_kpi__kpi__description",
  "supplier_kpi__kpi__level",
  "opened_at",
  "closed_at",
  "closure_date",
  "closed_quality",
  "affected_workers",
  "status__name"
];

export default function SupplierKpiUpdateHistoryTable(props, context) {
  const firstRender = useRef(true);
  const dispatch = useDispatch();
  const { setModal } = props;
  const [params, setParams] = useState({
    ...props.defaultParams,
    search: null,
    page: 1,
    page_size: 10,
    kpi__level: null,
    closed_at: null,
    closure_date: null,
    closed_quality: null
  });
  const cssclasses = props.cssclasses;
  const { list, total, loading } = useSelector(
    state => state.supplierKpiUpdateReducer
  );

  useEffect(() => {
    setParams(prevParams => ({
      ...prevParams, // Keep the existing filters set by the user
      ...props.defaultParams // Only overwrite fields provided by parent
    }));
  }, [props.defaultParams]);

  useEffect(() => {
    if (firstRender.current) {
      // Merge params from props.defaultParams only once during the initial render
      firstRender.current = false; // Set to false after the first render
    } else {
      // Dispatch fetchItems only when params change, excluding the initial merge
      dispatch(fetchItems(params));
    }
  }, [params, dispatch]);

  const onFilteredChange = useCallback(
    debounce(newFiltered => {
      const newParams = newFiltered.reduce((acc, { id, value }) => {
        acc[id] = value;
        return acc;
      }, {});

      setParams(prevParams => {
        const prevParamsDeepCopy = { ...prevParams };

        FILTERABLE_KEYS.forEach(key => {
          if (!(key in newParams) && key in prevParamsDeepCopy) {
            prevParamsDeepCopy[key] = null;
          }
        });

        return {
          ...prevParamsDeepCopy,
          ...newParams, // Apply new filters
          page: 1
        };
      });
    }, 500), // Adjust debounce delay as needed
    []
  );

  const onSearchChange = useCallback(
    debounce(value => {
      setParams(prevParams => ({
        ...prevParams,
        search: value
      }));
    }, 500), // Adjust debounce delay as needed
    []
  );

  const handleViewButtonClick = id => {
    setModal({
      open: true,
      id: id
    });
  };

  const handleView = id => (
    <Button
      title={context.t("View:") + id}
      simple
      color="info"
      value={id}
      className={cssclasses.button}
      onClick={e => handleViewButtonClick(e.currentTarget.value)}
    >
      <VisibilityIcon />
    </Button>
  );
  handleView.displayName = "handleView";

  const columns = createTableColumns({
    t: context.t,
    showHidden: params.show_closed,
    onView: handleView,
    classes: {
      oneLine: cssclasses.oneLine
    }
  });

  return (
    <GridItem xs={12}>
      <h4 className={cssclasses.center}>
        {context.t("Supplier KPI Update History")}
      </h4>

      <Card style={{ marginTop: 0 }}>
        <CardHeader>
          <h4>
            {context.t("Search Results")} ({context.t("Found")}-{total})
          </h4>
        </CardHeader>
        <CardBody>
          <GridItem xs={12} sm={12} lg={12}>
            <HtmlTooltip
              title={context.t(
                "You can enter a text or number thats used in the object.  Call IDs Also work. (You'll get the supplier KPIs that have that Call ID in the drop down panel or info view"
              )}
              interactive
            >
              <div className="cell-overflow">
                <FormControl fullWidth>
                  <CustomInput
                    id={"search_text"}
                    labelText={context.t(
                      "Search (Fuzzy Search Across all fields)"
                    )}
                    isTextArea={false}
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={params.search}
                    inputProps={{
                      onChange: e => onSearchChange(e.target.value)
                    }}
                  />
                </FormControl>
              </div>
            </HtmlTooltip>
          </GridItem>
          <GridItem>
            <br />
          </GridItem>

          <GridItem xs={12} sm={12} lg={12}>
            <Card>
              <CardBody>
                <ReactTable
                  PaginationComponent={Pagination}
                  data={list}
                  pages={
                    params.page_size > 0
                      ? Math.ceil(total / params.page_size)
                      : 0
                  }
                  page={params.page - 1}
                  filterable={true}
                  loading={loading}
                  manual
                  collapseOnDataChange={false}
                  defaultSorted={[
                    {
                      id:
                        params.show_closed === false
                          ? "opened_at"
                          : "closed_at",
                      desc: true
                    }
                  ]}
                  columns={columns}
                  defaultPageSize={10}
                  showPaginationTop={false}
                  showPaginationBottom={true}
                  className="-striped -highlight"
                  onPageChange={pageIndex => {
                    if (params.page !== pageIndex + 1) {
                      setParams({ ...params, page: pageIndex + 1 });
                    }
                  }}
                  onSortedChange={newSorted => {
                    const { id: columnName, desc } = newSorted[0];
                    const ordering = desc ? columnName : `-${columnName}`;
                    setParams({ ...params, ordering, page: 1 });
                  }}
                  onPageSizeChange={pageSize => {
                    if (params.page_size !== pageSize) {
                      setParams({ ...params, page_size: pageSize });
                    }
                  }}
                  onFilteredChange={onFilteredChange}
                />
              </CardBody>
            </Card>
          </GridItem>
        </CardBody>
      </Card>
    </GridItem>
  );
}

SupplierKpiUpdateHistoryTable.contextTypes = {
  t: PropTypes.func.isRequired
};

SupplierKpiUpdateHistoryTable.propTypes = {
  defaultParams: PropTypes.object.isRequired,
  setModal: PropTypes.func,
  cssclasses: PropTypes.object
};
