import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import PasswordResetView from "../users/PasswordResetView";
import ForgotPasswordView from "../users/ForgotPasswordView";
import { withRouter } from "react-router";

const useStyles = makeStyles(styles);

function PasswordResetPage(props) {
  const token = new URLSearchParams(window.location.search).get("token");

  const classes = useStyles();

  return (
    <GridContainer justifyContent="center" alignItems="center">
      <div>
        <GridContainer justifyContent="center" alignItems="center">
          <GridItem xs={12} sm={12} md={12}>
            {token !== undefined && token !== null && token !== "" ? (
              <PasswordResetView token={token} />
            ) : (
              <ForgotPasswordView />
            )}
          </GridItem>
        </GridContainer>
      </div>
    </GridContainer>
  );
}

export default withRouter(PasswordResetPage);
