import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import loginStore from "redux/stores/LoginStore.js";
import axios from "axios";

const API_BASE_URL = `${process.env.REACT_APP_API_URL}/suppliers/`;

export const fetchSupplierDocuments = createAsyncThunk(
  "supplierDocument/fetchDocuments",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}${id}/documents`, {
        params,
        headers: loginStore.getAxiosConfigHeaders().headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching data");
    }
  }
);

export const createSupplierDocument = createAsyncThunk(
  "supplierDocument/createDocument",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}${id}/documents/`,
        payload,
        {
          headers: loginStore.getAxiosConfigHeaders().headers
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error creating document");
    }
  }
);

export const deleteSupplierDocument = createAsyncThunk(
  "supplierDocument/deleteDocument",
  async ({ id, document_ids }, { rejectWithValue }) => {
    try {
      await axios.delete(`${API_BASE_URL}${id}/documents/`, {
        headers: loginStore.getAxiosConfigHeaders().headers,
        data: { document_ids }
      });
      return document_ids;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error deleting document");
    }
  }
);

export const updateSupplierDocument = createAsyncThunk(
  "supplierDocument/updateDocument",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${API_BASE_URL}${id}/documents/`,
        payload,
        {
          headers: loginStore.getAxiosConfigHeaders().headers
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error deleting document");
    }
  }
);

const supplierDocumentSlice = createSlice({
  name: "supplierDocument",
  initialState: {
    list: [],
    loading: false,
    isUploading: false,
    isDeleting: false,
    isUpdating: false,
    error: null,
    total: 0,
    requestParams: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSupplierDocuments.pending, state => {
        state.loading = true;
      })
      .addCase(fetchSupplierDocuments.fulfilled, (state, action) => {
        state.list = action.payload;
        state.loading = false;
      })
      .addCase(fetchSupplierDocuments.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(createSupplierDocument.pending, state => {
        state.isUploading = true;
      })
      .addCase(createSupplierDocument.fulfilled, (state, action) => {
        state.list = state.list.concat([action.payload]);
        state.isUploading = false;
      })
      .addCase(createSupplierDocument.rejected, (state, action) => {
        state.error = action.payload;
        state.isUploading = false;
      })
      .addCase(deleteSupplierDocument.pending, state => {
        state.isDeleting = true;
      })
      .addCase(deleteSupplierDocument.fulfilled, (state, action) => {
        state.list = state.list.filter(doc => !action.payload.includes(doc.id));
        state.isDeleting = false;
      })
      .addCase(deleteSupplierDocument.rejected, (state, action) => {
        state.error = action.payload;
        state.isDeleting = false;
      })
      .addCase(updateSupplierDocument.pending, state => {
        state.isUpdating = true;
      })
      .addCase(updateSupplierDocument.fulfilled, (state, action) => {
        const updatedDocument = action.payload;

        const targetIndex = state.list.findIndex(
          doc => doc.id === updatedDocument.id
        );

        if (targetIndex !== -1) {
          // Update state immutably
          state.list = state.list.map((doc, index) =>
            index === targetIndex ? updatedDocument : doc
          );
        }

        state.isUpdating = false;
      })
      .addCase(updateSupplierDocument.rejected, (state, action) => {
        state.error = action.payload;
        state.isUpdating = false;
      });
  }
});

export default supplierDocumentSlice.reducer;
