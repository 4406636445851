import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import GoogleMapReact from "google-map-react";
import loginStore from "../../redux/stores/LoginStore";
import {
  fetchSupplier,
  fetchSuppliers,
} from "../../redux/actions/SupplierActions";
import {
  fetchStrategicPartners,
  fetchSupplyChains,
} from "../../redux/actions/StrategicPartnerActions";
import { fetchCountries } from "../../redux/actions/LocaleActions";
import Utils from "../../services/utils";
import SupplierMessageBoardList from "./SupplierMessageBoardList";
import SharedFileList from "./SharedFileList";

import Tooltip from "@material-ui/core/Tooltip";

import { fetchSharedFiles } from "../../redux/actions/StrategicPartnerActions";

import IssaraInstitute from "assets/img/issara_institute.png";
// import NewsUpdateList from "views/strategicpartners/NewsUpdateList";

const customStyle = {
  ...styles,
  button: {
    padding: 0,
    margin: 0,
  },
  newInteractionGridItem: {
    // padding: '0 6px !important',
  },
  buttonBar: {
    marginLeft: "-15px !important",
  },
  gallery: {
    height: "100%",
    width: "100%",
    position: "relative",
  },

  galleryImg: {
    /* CSS Hack will make it width 100% and height 100% */
    position: "absolute",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
    /* Maintain aspect ratio */
    maxHeight: "100%",
    maxWidth: "100%",
  },
  galleryContainer: {
    width: "40px",
    height: "40px",
  },
  galleryContainerBigger: {
    width: "100px",
    height: "100px",
  },
};

const useStyles = makeStyles(customStyle);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const AnyReactComponent = ({ text }) => (
  <div>
    <HtmlTooltip title={text} interactive>
      <AccountCircleIcon style={{ fontSize: "18px", color: "red" }} />
    </HtmlTooltip>
  </div>
);

function SuppliersMapPanel(props) {
  // const classes = useStyles();
  const _user = loginStore.getLoginUser();
  const suppliers = props.suppliers ? props.suppliers : [];
  const suppliersMap = props.suppliersMap ? props.suppliersMap : {};

  const fullSuppliers = [];

  const defaultProps = {
    center: {
      lng: 100.5018,
      lat: 13.7563,
    },
    zoom: 6,
  };

  suppliers.forEach((supplier) => {
    let supplierObj = suppliersMap[supplier.id];
    console.log("Supplier Object:", supplierObj);
    if (!supplierObj && !supplierObj) {
      return null;
    } else if (
      supplierObj.lng &&
      supplierObj.lat &&
      Utils.isNumeric(supplierObj.lng) &&
      Utils.isNumeric(supplierObj.lat)
    ) {
      fullSuppliers.push(supplierObj);
    }
  });

  // Google Maps For Selected Supply Chain
  return (
    <div style={{ height: props.height, width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={({ map, maps }) =>
          console.log("Google Maps Loaded:", map, maps)
        }
        yesIWantToUseGoogleMapApiInternals
      >
        {fullSuppliers.map((item) => {
          let textStr = (
            <div>
              {" "}
              {_user.groups.includes("anonymous_user") ? (
                <div>
                  <u>Anonymous Supplier</u>
                </div>
              ) : (
                <div>{item.name}</div>
              )}
              {item.total_num_workers ? (
                <div>
                  <br />
                  {"" + item.total_num_workers + " total workers"}
                </div>
              ) : null}
              {item.total_num_thai_workers ? (
                <div>
                  <br />
                  {"" + item.total_num_thai_workers + " Thai workers"}
                </div>
              ) : null}
              {item.total_num_cambodian_workers ? (
                <div>
                  <br />
                  {"" + item.total_num_cambodian_workers + " Cambodian workers"}
                </div>
              ) : null}
              {item.total_num_myanmar_workers ? (
                <div>
                  <br />
                  {"" + item.total_num_myanmar_workers + " Myanmar workers"}
                </div>
              ) : null}
              {item.total_num_lao_workers ? (
                <div>
                  <br />
                  {"" + item.total_num_lao_workers + " Lao workers"}
                </div>
              ) : null}
              {item.total_num_vietnamese_workers ? (
                <div>
                  <br />
                  {"" +
                    item.total_num_vietnamese_workers +
                    " Vietanmese workers"}
                </div>
              ) : null}
            </div>
          );

          return (
            <AnyReactComponent
              key={Utils.giveMeGuid()}
              lat={item.lat}
              lng={item.lng}
              text={textStr}
            />
          );
        })}
      </GoogleMapReact>
    </div>
  );
}

export default function SupplierLandingPage(props, context) {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const WALMART_SUPPLY_CHAIN_ID = 13;

  const supplyChains = useSelector((state) => state.supplyChainReducer.items);
  const supplyChainsMap = useSelector(
    (state) => state.supplyChainReducer.itemsMap
  );

  const suppliers = useSelector((state) => state.suppliersReducer.items);
  const supplier = useSelector((state) => state.suppliersReducer.item);
  // const fetchingSuppliers = useSelector(state => state.suppliersReducer.fetchingSuppliers)
  const suppliersMap = useSelector((state) => state.suppliersReducer.itemsMap);

  const strategicPartners = useSelector(
    (state) => state.strategicPartnerReducer.strategicPartners
  );
  const strategicPartnersMap = useSelector(
    (state) => state.strategicPartnerReducer.strategicPartnersMap
  );

  // const sharedFiles = useSelector(state => state.sharedFilesReducer.sharedFiles);
  const sharedFilesMap = useSelector(
    (state) => state.sharedFilesReducer.sharedFilesMap
  );

  // FILTERS
  const [filters, setFilters] = useState({
    suppliers: null,
    supplyChain: null,
    startTime: null,
    endTime: null,
    industry: null,
    subindustry: null,
    disableSupplyChainSelect: false,
    partner: null,
    search: "",
    temporarySP: {},
  });

  useEffect(() => {
    const user = loginStore.getLoginUser();
    dispatch(fetchSupplier(user.supplier));
    dispatch(fetchSharedFiles());
    dispatch(fetchSupplyChains());
    dispatch(fetchCountries());
    dispatch(fetchStrategicPartners());
    dispatch(fetchSuppliers()); // Done for child controls/modal
  }, []);

  useEffect(() => {
    if (
      strategicPartners &&
      strategicPartners.forEach !== undefined &&
      strategicPartners.length > 0 &&
      supplyChains &&
      supplyChains.forEach !== undefined &&
      supplyChains.length > 0
    ) {
      let partnerData = Utils.findPartnerAndSupplyChain(
        loginStore.getLoginUser(),
        supplyChains,
        strategicPartners
      );

      setFilters({
        ...filters,
        partner: partnerData.partnerFound,
        disableSupplyChainSelect: partnerData.foundSupplyChain !== null,
        supplyChain: partnerData.foundSupplyChain,
        suppliers:
          partnerData.foundSupplyChain && partnerData.foundSupplyChain.suppliers
            ? (() => {
                let array = [];
                partnerData.foundSupplyChain.suppliers.forEach((supplierId) => {
                  array.push("" + supplierId);
                });
                return array;
              })()
            : null,
      });
    }
  }, [suppliers, strategicPartners, supplyChains]);

  useEffect(() => {
    if (supplier) {
      setFilters({
        ...filters,
        suppliers: [supplier],
      });
    }
  }, [supplier]);

  // temporay sp for demo
  useEffect(() => {
    if (strategicPartners) {
      const strategicPartner = strategicPartners.find(
        (sp) => sp.name === "Thai Union Group"
      );
      setFilters({
        ...filters,
        temporarySP: strategicPartner ? strategicPartner : "",
      });
    }
  }, [strategicPartners]);

  if (suppliers === undefined || suppliers === null || suppliers.length < 1) {
    return (
      <GridContainer>
        <CircularProgress />
      </GridContainer>
    );
  }

  return (
    <GridContainer>
      <GridContainer>
        <SharedFileList />
      </GridContainer>
      <GridContainer>
        <SupplierMessageBoardList
          key={Utils.giveMeGuid()}
          partnerID={filters.temporarySP.id}
        />
      </GridContainer>

      {!filters.suppliers ||
      filters.suppliers.length < 1 ||
      !suppliersMap ||
      Object.keys(suppliersMap).length < 1 ? null : (
        <GridContainer>
          <SuppliersMapPanel
            height={"70vh"}
            width={"100%"}
            suppliers={filters.suppliers}
            suppliersMap={suppliersMap}
          />
        </GridContainer>
      )}
    </GridContainer>
  );
}

SupplierLandingPage.contextTypes = {
  t: PropTypes.func.isRequired,
};
