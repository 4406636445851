import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Datetime from "react-datetime";
import SweetAlert from "react-bootstrap-sweetalert";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import axios from "axios";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import ButtonBar from "components/ButtonBar/ButtonBar.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import SupplierKpiUpdateStatusesDropdown from "components/ilmdb/SupplierKpiUpdateStatusesDropdown.js";
import { updateSupplierKPI } from "redux/actions/SupplierActions.js";
import IssaraStaffDropdown from "components/ilmdb/IssaraStaffDropdown.js";
import { makeStyles } from "@material-ui/core/styles";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import Utils from "services/utils.js";
import loginStore from "redux/stores/LoginStore.js";
import PreviewGrievance from "components/Grievance/PreviewGrievance";
import SummaryComponent from "components/Grievance/SummaryComponent";

import {
  fetchSupplierKpiById,
  fetchSupplierKpiCalls
} from "redux/stores/supplierKpi/supplierKpiSlice";

let _user = loginStore.getLoginUser();

const customStyle = {
  ...customCheckboxRadioSwitch,
  ...sweetAlertStyles,
  label: {
    color: "black",
    fontSize: "14px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.42857
  },
  buttonLabel: {
    marginBottom: "-10px"
  },
  spanLabel: {
    fontWeight: 500
  },
  customCard: {
    marginTop: 0
  },
  customGridItem: {
    marginTop: "-20px"
  },
  checkLabel: {
    display: "flex",
    justifyContent: "start",
    marginTop: "5px",
    alignItems: "end",
    cursor: "pointer"
  },
  callLink: {
    paddingRight: "0.35em",
    whiteSpace: "nowrap"
  }
};
const useStyles = makeStyles(customStyle);

const CALL_URL = "/admin/new-call?id=";

const getTotalCalls = callList => {
  return Array.isArray(callList) ? callList.length.toString() : "Loading...";
};

const renderCallsList = (callsList, classes) => {
  if (!Array.isArray(callsList)) return null;

  const sortedList = [...callsList].sort((a, b) => a.id - b.id);

  return sortedList.map((call, index) => {
    return (
      <span key={`${index}-${call.id}`} className={classes.callLink}>
        {"[ "}
        <a
          href={`${CALL_URL}${call.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {call.id}
        </a>
        {" ]"}
      </span>
    );
  });
};

const KPIStatusOverviewTab = ({
  state,
  setState,
  classes,
  context,
  SUPPLIER_UPDATE_KPI_STATUS_CLOSED_ID,
  SUPPLIER_UPDATE_KPI_STATUS_CLOSED_UNRESOLVED_ID,
  valueEmpty,
  handleCheck
}) => {
  return (
    <GridContainer>
      <GridItem lg={12}>
        <SupplierKpiUpdateStatusesDropdown
          key={Utils.giveMeGuid()}
          value={state.status}
          onSelect={e => {
            let value =
              e.target.value === undefined || e.target.value === valueEmpty
                ? undefined
                : Number.parseInt(e.target.value);

            setState({
              ...state,
              status: value,
              closed_at:
                value === SUPPLIER_UPDATE_KPI_STATUS_CLOSED_ID ||
                value === SUPPLIER_UPDATE_KPI_STATUS_CLOSED_UNRESOLVED_ID
                  ? new Date().toISOString().split("T")[0]
                  : null
            });
          }}
        />
      </GridItem>

      <GridItem lg={12}>
        <p className={classes.label} style={{ marginBottom: 0 }}>
          {context.t("Opened At:")}
        </p>
        <Datetime
          timeFormat={false}
          inputProps={{
            placeholder: context.t("Click here to open calendar")
          }}
          closeOnSelect={true}
          onChange={newDate =>
            setState({
              ...state,
              opened_at: newDate?.format?.("YYYY-MM-DD") ?? newDate
            })
          }
          value={state.opened_at}
        />
      </GridItem>

      {(state.status === SUPPLIER_UPDATE_KPI_STATUS_CLOSED_ID ||
        state.status === SUPPLIER_UPDATE_KPI_STATUS_CLOSED_UNRESOLVED_ID) && (
        <GridContainer>
          <GridItem lg={12}>
            <p className={classes.label} style={{ marginBottom: 0 }}>
              {context.t("Closed At:")}
            </p>
            <Datetime
              timeFormat={false}
              inputProps={{
                placeholder: context.t("Click here to open calendar")
              }}
              closeOnSelect={true}
              onChange={newDate =>
                setState({
                  ...state,
                  closed_at: newDate?.format?.("YYYY-MM-DD") ?? newDate
                })
              }
              value={state.closed_at}
            />
          </GridItem>
          <GridItem lg={12}>
            <p className={classes.label + " " + classes.buttonLabel}>
              {context.t("Please assess quality of remediation")}
            </p>
            <ButtonBar
              value={state.closed_quality}
              labelWidth={12}
              buttons={[
                { name: "Poor", value: "Poor" },
                { name: "Fair", value: "Fair" },
                { name: "Good", value: "Good" },
                { name: "Excellent", value: "Excellent" }
              ]}
              onClick={(name, value) => {
                setState({ ...state, closed_quality: value });
              }}
            />
          </GridItem>
          <GridItem lg={12}>
            <p className={classes.label} style={{ marginBottom: "-16px" }}>
              {context.t(
                "Please explain the rationale for this remediation outcome assessment."
              )}
            </p>
            <br />
            <CustomInput
              isTextArea={true}
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: e => {
                  setState({
                    ...state,
                    closed_notes: e.target.value
                  });
                }
              }}
              value={state.closed_notes}
            />
          </GridItem>
        </GridContainer>
      )}

      <GridItem xs={12} lg={12}>
        <CustomInput
          labelText={context.t(
            "Number of affected workers related to this KPI"
          )}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            type: "number",
            onChange: e => {
              setState({
                ...state,
                affected_workers: Math.max(0, e.target.value)
              });
            }
          }}
          value={`${parseInt(state.affected_workers)}`}
        />
      </GridItem>

      <GridItem lg={12}>
        <p
          className={classes.label + " " + classes.buttonLabel}
          style={{ marginTop: "15px", marginBottom: "6px" }}
        >
          {context.t("Have there been any retaliation or threats to workers?")}
        </p>
        <ButtonBar
          value={state.retaliation}
          labelWidth={12}
          buttons={[{ name: "Yes", value: true }, { name: "No", value: false }]}
          onClick={(name, value) => setState({ ...state, retaliation: value })}
        />
      </GridItem>
      <GridItem>
        <label className={classes.checkLabel} htmlFor="check1">
          {state.hide === null ? (
            <input
              type="checkbox"
              id="check1"
              value="false"
              checked="false"
              onChange={handleCheck}
              name=""
            />
          ) : (
            <input
              type="checkbox"
              id="check1"
              value={state.hide}
              checked={state.hide}
              onChange={handleCheck}
              name=""
            />
          )}
          {context.t(
            "Do not include in Supplier Dashboard as per risk assessment."
          )}
        </label>
      </GridItem>
    </GridContainer>
  );
};

const AssessmentRemediationTab = ({
  state,
  setState,
  classes,
  context,
  grievanceData
}) => {
  return (
    <GridContainer>
      <GridItem lg={12}>
        <p className={classes.label} style={{ marginBottom: "-16px" }}>
          {_user?.groups?.includes("CSO")
            ? context.t(
                "Please summarize the organization's recommendations for remediation to address this KPI, including timeframe"
              )
            : context.t(
                "Please summarize Issara's recommendations for remediation to address this KPI, including timeframe"
              )}
        </p>
        <br />
        <CustomInput
          isTextArea={true}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            onChange: e => {
              setState({
                ...state,
                remediation_issara_recommendation: e.target.value
              });
            }
          }}
          value={state.remediation_issara_recommendation}
        />
      </GridItem>

      <GridItem lg={12}>
        <p className={classes.label} style={{ marginBottom: 0 }}>
          {_user?.groups?.includes("CSO")
            ? context.t(
                "What date has been communicated as the recommended deadline for the needed remediation or the next action?"
              )
            : context.t(
                "What date has been communicated as the recommended deadline for completion of payments and/or next major payment milestone?"
              )}
        </p>
        <Datetime
          timeFormat={false}
          inputProps={{
            placeholder: context.t("Click here to open calendar")
          }}
          closeOnSelect={true}
          onChange={date =>
            setState({
              ...state,
              remediation_payment_deadline: date?.format?.("YYYY-MM-DD") || date
            })
          }
          value={state.remediation_payment_deadline}
        />
      </GridItem>

      <GridItem lg={12}>
        <p className={classes.label + " " + classes.buttonLabel}>
          {_user?.groups?.includes("CSO")
            ? context.t(
                "How much progress has been made on remediation, as recommended by the organization?"
              )
            : context.t(
                "How much progress has been made on remediation, as recommended by Issara?"
              )}
        </p>
        <ButtonBar
          value={state.remediation_progress}
          labelWidth={12}
          buttons={[
            { name: "None", value: "None" },
            { name: "A little", value: "A little" },
            { name: "A lot", value: "A lot" }
          ]}
          onClick={(name, value) =>
            setState({ ...state, remediation_progress: value })
          }
        />
      </GridItem>

      <GridItem lg={12}>
        <p className={classes.label + " " + classes.buttonLabel}>
          {_user?.groups?.includes("CSO")
            ? context.t(
                "How aligned are the responses with the recommendations of the organization based on international ethical standards?"
              )
            : context.t(
                "How aligned are the business's responses with Issara's recommendations and international ethical standards?"
              )}
        </p>
        <ButtonBar
          value={state.remediation_aligned}
          labelWidth={12}
          buttons={[
            { name: "None", value: "None" },
            { name: "A little", value: "A little" },
            { name: "A lot", value: "A lot" }
          ]}
          onClick={(name, value) =>
            setState({ ...state, remediation_aligned: value })
          }
        />
      </GridItem>

      <GridItem xs={12} lg={6}>
        <p className={classes.label} style={{ marginBottom: "-16px" }}>
          {_user?.groups?.includes("CSO")
            ? context.t("Describe steps taken so far.")
            : context.t(
                "Describe steps taken by offender/s or responsible party to solve the issue so far."
              )}
        </p>
        <br />
        <br />
        <CustomInput
          isTextArea={true}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            onChange: e => {
              setState({
                ...state,
                remediation_business_steps_taken: e.target.value
              });
            }
          }}
          value={state.remediation_business_steps_taken}
        />
      </GridItem>

      <GridItem xs={12} lg={6}>
        <p className={classes.label} style={{ marginBottom: "-16px" }}>
          {_user?.groups?.includes("CSO")
            ? context.t("Describe remaining steps.")
            : context.t("Describe steps remaining by the offender/s.")}
        </p>
        <br />
        <br />
        <CustomInput
          isTextArea={true}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            onChange: e => {
              setState({
                ...state,
                remediation_business_steps_remaining: e.target.value
              });
            }
          }}
          value={state.remediation_business_steps_remaining}
        />
      </GridItem>
      {"list_of_text_fields" in grievanceData ? (
        <GridItem xs={12}>
          <SummaryComponent
            data={grievanceData.list_of_text_fields.remediations}
            context={context}
            title="Supplier's notes on case remediation"
            header="Remediation: "
          />
        </GridItem>
      ) : null}

      <GridItem xs={12}>
        <p className={classes.label}>
          {context.t(
            "How much money is owed in total to all workers? (If none is owed, please enter '0')"
          )}
        </p>
      </GridItem>

      <GridItem xs={3} className={classes.customGridItem}>
        <CustomInput
          labelText={context.t("Thai Baht")}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            type: "number",
            onChange: e => {
              setState({
                ...state,
                remediation_owed_baht: Math.max(e.target.value, 0)
              });
            }
          }}
          value={`${parseInt(state.remediation_owed_baht)}`}
        />
      </GridItem>
      <GridItem xs={3} className={classes.customGridItem}>
        <CustomInput
          labelText={context.t("Myanmar Kyat")}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            type: "number",
            onChange: e => {
              setState({
                ...state,
                remediation_owed_kyat: Math.max(e.target.value, 0)
              });
            }
          }}
          value={`${parseInt(state.remediation_owed_kyat)}`}
        />
      </GridItem>
      <GridItem xs={3} className={classes.customGridItem}>
        <CustomInput
          labelText={context.t("Malaysian Ringitt")}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            type: "number",
            onChange: e => {
              setState({
                ...state,
                remediation_owed_ringitt: Math.max(e.target.value, 0)
              });
            }
          }}
          value={`${parseInt(state.remediation_owed_ringitt)}`}
        />
      </GridItem>
      <GridItem xs={3} className={classes.customGridItem}>
        <CustomInput
          labelText={context.t("US Dollars")}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            type: "number",
            onChange: e => {
              setState({
                ...state,
                remediation_owed_usd: Math.max(e.target.value, 0)
              });
            }
          }}
          value={`${parseInt(state.remediation_owed_usd)}`}
        />
      </GridItem>

      <GridItem xs={12}>
        <p className={classes.label}>
          {context.t(
            "How much money has been paid back so far? (If none has been paid, please enter '0')"
          )}
        </p>
      </GridItem>

      <GridItem xs={3} className={classes.customGridItem}>
        <CustomInput
          labelText={context.t("Thai Baht")}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            type: "number",
            onChange: e => {
              setState({
                ...state,
                remediation_paid_baht: Math.max(e.target.value, 0)
              });
            }
          }}
          value={`${parseInt(state.remediation_paid_baht)}`}
        />
      </GridItem>
      <GridItem xs={3} className={classes.customGridItem}>
        <CustomInput
          labelText={context.t("Myanmar Kyat")}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            type: "number",
            onChange: e => {
              setState({
                ...state,
                remediation_paid_kyat: Math.max(e.target.value, 0)
              });
            }
          }}
          value={`${parseInt(state.remediation_paid_kyat)}`}
        />
      </GridItem>
      <GridItem xs={3} className={classes.customGridItem}>
        <CustomInput
          labelText={context.t("Malaysian Ringitt")}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            type: "number",
            onChange: e => {
              setState({
                ...state,
                remediation_paid_ringitt: Math.max(e.target.value, 0)
              });
            }
          }}
          value={`${parseInt(state.remediation_paid_ringitt)}`}
        />
      </GridItem>
      <GridItem xs={3} className={classes.customGridItem}>
        <CustomInput
          labelText={context.t("US Dollars")}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            type: "number",
            onChange: e => {
              setState({
                ...state,
                remediation_paid_usd: Math.max(e.target.value, 0)
              });
            }
          }}
          value={`${parseInt(state.remediation_paid_usd)}`}
        />
      </GridItem>

      <GridItem xs={12} className={classes.customGridItem}>
        <CustomInput
          labelText={context.t(
            "How many workers have received payments to date?"
          )}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            type: "number",
            onChange: e => {
              setState({
                ...state,
                remediation_workers_paid: Math.max(e.target.value, 0)
              });
            }
          }}
          value={`${parseInt(state.remediation_workers_paid)}`}
        />
      </GridItem>

      <GridItem xs={12} className={classes.customGridItem}>
        <CustomInput
          labelText={context.t(
            "How many workers are owed documents, visas, or WPs?"
          )}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            type: "number",
            onChange: e => {
              setState({
                ...state,
                remediation_documents_owed: Math.max(e.target.value, 0)
              });
            }
          }}
          value={`${parseInt(state.remediation_documents_owed)}`}
        />
      </GridItem>

      <GridItem xs={12} className={classes.customGridItem}>
        <CustomInput
          labelText={context.t(
            "How many workers have received back their documents, visas, or WPs?"
          )}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            type: "number",
            onChange: e => {
              setState({
                ...state,
                remediation_documents_provided: Math.max(e.target.value, 0)
              });
            }
          }}
          value={`${parseInt(state.remediation_documents_provided)}`}
        />
      </GridItem>

      <GridItem lg={12}>
        <p className={classes.label} style={{ marginBottom: 0 }}>
          {context.t(
            "What date has been communicated as the recommended deadline for return of documents and/or next major document processing milestone?"
          )}
        </p>
        <Datetime
          timeFormat={false}
          inputProps={{
            placeholder: context.t("Click here to open calendar")
          }}
          closeOnSelect={true}
          onChange={date =>
            setState({
              ...state,
              remediation_documents_deadline:
                date?.format?.("YYYY-MM-DD") ?? date
            })
          }
          value={state.remediation_documents_deadline}
        />
      </GridItem>
      {_user?.groups?.includes("CSO") ? null : (
        <div>
          <GridItem lg={12}>
            <p className={classes.label} style={{ marginBottom: "-16px" }}>
              <strong>
                {context.t("(* Special field for Director/s only). Issues")}
              </strong>
            </p>
            <br />
            <CustomInput
              isTextArea={true}
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: e => {
                  setState({
                    ...state,
                    remediation_notes: e.target.value
                  });
                }
              }}
              value={state.remediation_notes}
            />
          </GridItem>
          <GridItem lg={12}>
            <p className={classes.label} style={{ marginBottom: "-16px" }}>
              <strong>
                {context.t("(* Special field for Director/s only). Action")}
              </strong>
            </p>
            <br />
            <CustomInput
              isTextArea={true}
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: e => {
                  setState({
                    ...state,
                    remediation_action: e.target.value
                  });
                }
              }}
              value={state.remediation_action}
            />
          </GridItem>
          <GridItem lg={12}>
            <p className={classes.label} style={{ marginBottom: "-16px" }}>
              <strong>
                {context.t(
                  "(* Special field for Director/s only). Worker Validation"
                )}
              </strong>
            </p>
            <br />
            <CustomInput
              isTextArea={true}
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: e => {
                  setState({
                    ...state,
                    remediation_validation: e.target.value
                  });
                }
              }}
              value={state.remediation_validation}
            />
          </GridItem>
          <GridItem lg={12}>
            <p className={classes.label} style={{ marginBottom: "-16px" }}>
              <strong>
                {context.t("(* Special field for Director/s only). Results")}
              </strong>
            </p>
            <br />
            <CustomInput
              isTextArea={true}
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: e => {
                  setState({
                    ...state,
                    remediation_results: e.target.value
                  });
                }
              }}
              value={state.remediation_results}
            />
          </GridItem>
        </div>
      )}
    </GridContainer>
  );
};

const AssessmentSystemChangeTab = ({
  state,
  setState,
  classes,
  context,
  grievanceData,
  handlePreview,
  showPreview
}) => {
  return (
    <GridContainer>
      <GridItem lg={12}>
        <p className={classes.label} style={{ marginBottom: "-16px" }}>
          {context.t(
            "Please summarize Issara's recommendations for systems change to address this KPI, including timeframe."
          )}
        </p>
        <br />
        <CustomInput
          isTextArea={true}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            onChange: e => {
              setState({
                ...state,
                systems_change_issara_recommendation: e.target.value
              });
            }
          }}
          value={state.systems_change_issara_recommendation}
        />
      </GridItem>

      <GridItem lg={12}>
        <p className={classes.label + " " + classes.buttonLabel}>
          {_user?.groups?.includes("CSO")
            ? context.t(
                "How much progress has been made on systems change, as recommended by the organization?"
              )
            : context.t(
                "How much progress has been made on systems change, as recommended by Issara?"
              )}
        </p>
        <ButtonBar
          value={state.systems_change_progress}
          labelWidth={12}
          buttons={[
            { name: "None", value: "None" },
            { name: "A little", value: "A little" },
            { name: "A lot", value: "A lot" }
          ]}
          onClick={(name, value) =>
            setState({
              ...state,
              systems_change_progress: value
            })
          }
        />
      </GridItem>
      <GridItem lg={12}>
        <p className={classes.label + " " + classes.buttonLabel}>
          {_user?.groups?.includes("CSO")
            ? context.t(
                "How aligned are the responses with the recommendations of the organization based on international ethical standards?"
              )
            : context.t(
                "How aligned are the business's responses with Issara's recommendations and international ethical standards?"
              )}
        </p>
        <ButtonBar
          value={state.systems_change_aligned}
          labelWidth={12}
          buttons={[
            { name: "None", value: "None" },
            { name: "A little", value: "A little" },
            { name: "A lot", value: "A lot" }
          ]}
          onClick={(name, value) =>
            setState({
              ...state,
              systems_change_aligned: value
            })
          }
        />
      </GridItem>
      <GridItem xs={12} lg={6}>
        <p className={classes.label} style={{ marginBottom: "-16px" }}>
          {_user?.groups?.includes("CSO")
            ? context.t("Describe steps taken so far.")
            : context.t(
                "Describe steps taken by offender/s or responsible party to solve the issue so far."
              )}
        </p>
        <br />
        <br />
        <CustomInput
          isTextArea={true}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            onChange: e => {
              setState({
                ...state,
                systems_change_business_steps_taken: e.target.value
              });
            }
          }}
          value={state.systems_change_business_steps_taken}
        />
      </GridItem>
      <GridItem xs={12} lg={6}>
        <p className={classes.label} style={{ marginBottom: "-16px" }}>
          {_user?.groups?.includes("CSO")
            ? context.t("Describe remaining steps.")
            : context.t("Describe steps remaining by the offender/s.")}
        </p>
        <br />
        <br />
        <CustomInput
          isTextArea={true}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            onChange: e => {
              setState({
                ...state,
                systems_change_business_steps_remaining: e.target.value
              });
            }
          }}
          value={state.systems_change_business_steps_remaining}
        />
      </GridItem>

      {"list_of_text_fields" in grievanceData ? (
        <GridItem xs={12}>
          <SummaryComponent
            data={grievanceData?.list_of_text_fields?.system_strengthenings}
            context={context}
            title="Supplier's notes on systems strengthening and continuous improvement related to this worker-reported issue:"
            header="System Strengthenings: "
          />
        </GridItem>
      ) : null}
      <GridContainer justify="center" alignItems="center">
        <GridItem>
          {"list_of_text_fields" in grievanceData ? (
            <Button color="rose" onClick={handlePreview}>
              {context.t(
                "See supplier's grievance mechanism process in detail"
              )}
            </Button>
          ) : null}
        </GridItem>
      </GridContainer>
      {Object.keys(grievanceData)?.length > 0 && (
        <PreviewGrievance
          context={context}
          data={grievanceData}
          open={showPreview}
          handleModalClose={handlePreview}
          isPreview={true}
        />
      )}
    </GridContainer>
  );
};

const NextStepsTab = ({
  state,
  setState,
  classes,
  context,
  onSubmit,
  saving
}) => {
  return (
    <GridContainer>
      <GridItem lg={12}>
        <p className={classes.label} style={{ marginBottom: "-16px" }}>
          {context.t("Description")}
        </p>
        <br />
        <CustomInput
          isTextArea={true}
          formControlProps={{ fullWidth: true }}
          inputProps={{
            onChange: e => {
              setState({
                ...state,
                next_description: e.target.value
              });
            }
          }}
          value={state.next_description}
        />
      </GridItem>

      <GridItem lg={12}>
        <p className={classes.label + " " + classes.buttonLabel}>
          {context.t("Deadline date")}
        </p>
        <Datetime
          timeFormat={false}
          inputProps={{
            placeholder: context.t("Click here to open calendar")
          }}
          closeOnSelect={true}
          onChange={date =>
            setState({
              ...state,
              deadline: date?.format?.("YYYY-MM-DD") ?? date
            })
          }
          value={state.deadline}
        />
      </GridItem>

      <GridItem xs={12}>
        <IssaraStaffDropdown
          value={state.assigned_users}
          multipleselect={true}
          onSelect={e => {
            setState({ ...state, assigned_users: e });
          }}
          name={"Assigned Person"}
          text={context.t("fatching staff..")}
        />
      </GridItem>

      <GridContainer justify="flex-end">
        <GridItem>
          <Button color="success" onClick={onSubmit} disabled={saving}>
            {context.t("Update")}
          </Button>
        </GridItem>
      </GridContainer>
    </GridContainer>
  );
};
// This form needs to be broken into smaller components tab by tab
// right now it is quite hard to manage anything in it.
export default function SupplierKpiUpdateForm(props, context) {
  let { id, onUpdateKPI, calls, supplierKpiCopy } = props;
  const valueEmpty = " ";
  const SUPPLIER_UPDATE_KPI_STATUS_CLOSED_ID = 3;
  const SUPPLIER_UPDATE_KPI_STATUS_CLOSED_UNRESOLVED_ID = 4;
  const classes = useStyles();
  const dispatch = useDispatch();

  let { supplierKPI, calls: stateCalls } = useSelector(state => ({
    supplierKPI: state.supplierKpiReducer.selectedById[id],
    calls: state.supplierKpiReducer.callsById[id] || []
  }));

  if (supplierKpiCopy) {
    console.log("should have overwritten")
    
    supplierKPI = {...supplierKpiCopy, kpi: supplierKPI.kpi}
  }

  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  if (!calls) {
    calls = stateCalls;
  }

  const [saving, setSaving] = useState(false);
  const [finished, setFinished] = useState(false);
  const [alert, setAlert] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [grievanceData, setGrievanceData] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);
  const [state, setState] = useState(() => {
    if (supplierKpiCopy) return { ...supplierKpiCopy };
    if (supplierKPI) return { ...supplierKPI };
    return {};
  });

  useEffect(() => {
    let mounted = true;

    const loadData = async () => {
      try {
        const [kpiResult, , grievanceResult] = await Promise.all([
          dispatch(fetchSupplierKpiById({ id })),
          dispatch(fetchSupplierKpiCalls({ id })),
          axios.get(
            `${process.env.REACT_APP_API_URL}/supplier-kpis/${id}/grievance/`
          )
        ]);

        if (mounted) {
          if (grievanceResult.data.length > 0) {
            setGrievanceData(grievanceResult.data[0]);
          }

          // Only set state if it hasn't been modified by user
          if (!dataLoaded) {
            if (supplierKpiCopy) {
              setState(prevState => ({
                ...prevState,
                ...supplierKpiCopy
              }));
            }

            setState(prevState => ({
              ...prevState,
              ...kpiResult.payload
            }));
            setDataLoaded(true);
          }

          setInitialLoadDone(true);
        }
      } catch (error) {
        console.error("Error loading data:", error);
        if (mounted) {
          setInitialLoadDone(true);
        }
      }
    };

    if (!initialLoadDone && !supplierKPI) {
      loadData();
    }

    return () => {
      mounted = false;
    };
  }, [id, dispatch, initialLoadDone]);

  useEffect(() => {
    if (supplierKpiCopy && !dataLoaded) {
      setState(prevState => ({
        ...prevState,
        ...supplierKpiCopy,
      }));
      setDataLoaded(true);
    }

    if (supplierKPI && !dataLoaded) {
      setState(prevState => ({
        ...prevState,
        ...supplierKPI,
      }));
      setDataLoaded(true);
    }
  }, [JSON.stringify(supplierKPI)]);

  const successAlert = () => {
    onUpdateKPI();
    setFinished(true);
    setSaving(false);
  };

  const errorAlert = error => {
    setAlert(
      <SweetAlert
        danger
        onConfirm={() => {
          setAlert(null);
          setSaving(false);
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        title="Error"
      >
        {error.message}
      </SweetAlert>
    );
  };

  const handlePreview = () => {
    setShowPreview(!showPreview);
  };

  const onSubmit = () => {
    setSaving(true);

    if (
      (state.status === SUPPLIER_UPDATE_KPI_STATUS_CLOSED_ID ||
        state.status === SUPPLIER_UPDATE_KPI_STATUS_CLOSED_UNRESOLVED_ID) &&
      (state.closed_at === null || state.closed_at === undefined)
    ) {
      errorAlert({
        message: context.t("Must set closed At Date when closing")
      });
      return;
    }

    if (state.opened_at === null || state.opened_at === undefined) {
      errorAlert({
        message: context.t("Must set openedAt, this field can't be blank")
      });
      return;
    }

    const payload = {
      ...state,
      overview_date: new Date().toISOString().split("T")[0],
      kpi_update_source: "supplierkpiupdateform",
      closed_quality:
        state.status === SUPPLIER_UPDATE_KPI_STATUS_CLOSED_ID ||
        state.status === SUPPLIER_UPDATE_KPI_STATUS_CLOSED_UNRESOLVED_ID
          ? state.closed_quality || "Poor"
          : null,
      closed_notes:
        state.status === SUPPLIER_UPDATE_KPI_STATUS_CLOSED_ID ||
        state.status === SUPPLIER_UPDATE_KPI_STATUS_CLOSED_UNRESOLVED_ID
          ? state.closed_notes
          : null,
      systems_change_deadline:
        state.systems_change_deadline instanceof Date
          ? state.systems_change_deadline.toISOString().split("T")[0]
          : null,
      open:
        state.status !== SUPPLIER_UPDATE_KPI_STATUS_CLOSED_ID ||
        state.status !== SUPPLIER_UPDATE_KPI_STATUS_CLOSED_UNRESOLVED_ID,
      closed_at:
        state.status === SUPPLIER_UPDATE_KPI_STATUS_CLOSED_ID ||
        state.status === SUPPLIER_UPDATE_KPI_STATUS_CLOSED_UNRESOLVED_ID
          ? new Date(state.closed_at).toISOString().split("T")[0]
          : null,
      assigned_to: state.assigned_to?.id || state.assigned_to,
      assigned_users: state.assigned_users || []
    };
    dispatch(updateSupplierKPI(id, payload, successAlert, errorAlert));
  };

  const handleCheck = () => {
    setState({ ...state, hide: !state.hide });
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return supplierKPI && dataLoaded ? (
    <Card
      className={classes.customCard}
      style={{ display: finished && "none" }}
    >
      {alert}
      <CardBody>
        <GridContainer>
          <GridItem xs={12} lg={4}>
            <Card
              className={classes.customCard}
              style={{ position: "sticky", top: "20px" }}
            >
              <CardBody>
                <div>
                  <p>
                    <span className={classes.spanLabel}>
                      {context.t("Update Source:")}
                    </span>
                    {supplierKPI.kpi_update_source || "N/A"}
                  </p>
                  <p>
                    <span className={classes.spanLabel}>
                      {context.t("Supplier:")}
                    </span>
                    {supplierKPI.supplier_name}
                  </p>
                  <p>
                    <span className={classes.spanLabel}>
                      {context.t("Category:")}
                    </span>
                    {supplierKPI.category}
                  </p>
                  <p>
                    <span className={classes.spanLabel}>
                      {context.t("Description:")}
                    </span>
                    {supplierKPI.description}
                  </p>
                  <p>
                    <span className={classes.spanLabel}>
                      {context.t("Level:")}
                    </span>
                    {supplierKPI.level}
                  </p>
                  <p>
                    <span className={classes.spanLabel}>
                      {context.t("Open Since:")}
                    </span>
                    {supplierKPI.opened_at}
                  </p>
                  <p>
                    <span className={classes.spanLabel}>
                      {context.t("Remediation:")}
                    </span>
                    {supplierKPI.remediation}
                  </p>
                  <p>
                    <span className={classes.spanLabel}>
                      {context.t("Remediation Deadline:")}
                    </span>
                    {context.t("Not implemented")}
                  </p>
                  <p>
                    <span className={classes.spanLabel}>
                      {context.t("Systems Change:")}
                    </span>
                    {supplierKPI.systems_change}
                  </p>
                  <p>
                    <span className={classes.spanLabel}>
                      {context.t("Systems Change Deadline:")}
                    </span>
                    {supplierKPI.systems_change_deadline}
                  </p>
                  <p>
                    <span className={classes.spanLabel}>
                      {context.t("# Impacted:")}
                    </span>
                    {supplierKPI.affected_workers}
                  </p>
                  <p>
                    <span className={classes.spanLabel}>
                      {context.t("Laws Violated:")}
                    </span>
                    {supplierKPI.law}
                  </p>
                  <p>
                    <span className={classes.spanLabel}>
                      {`Associated Calls`}
                    </span>
                    <br></br>
                    {`Total Calls: ${getTotalCalls(calls)}`}
                    <br></br>
                    {renderCallsList(calls, classes)}
                  </p>
                </div>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} lg={8} style={{ marginTop: "-30px" }}>
            <CustomTabs
              headerColor="rose"
              value={selectedTab} // Pass the selected tab index
              handleChange={handleTabChange}
              grievance={true}
              tabs={[
                {
                  tabName: context.t("KPI Status Overview"),
                  tabContent: (
                    <KPIStatusOverviewTab
                      state={state}
                      setState={setState}
                      classes={classes}
                      context={context}
                      SUPPLIER_UPDATE_KPI_STATUS_CLOSED_ID={
                        SUPPLIER_UPDATE_KPI_STATUS_CLOSED_ID
                      }
                      SUPPLIER_UPDATE_KPI_STATUS_CLOSED_UNRESOLVED_ID={
                        SUPPLIER_UPDATE_KPI_STATUS_CLOSED_UNRESOLVED_ID
                      }
                      valueEmpty={valueEmpty}
                      handleCheck={handleCheck}
                    />
                  )
                },
                {
                  tabName: "Assessment: remediation",
                  tabContent: (
                    <AssessmentRemediationTab
                      state={state}
                      setState={setState}
                      classes={classes}
                      context={context}
                      grievanceData={grievanceData}
                    />
                  )
                },
                {
                  tabName: context.t("Assessment: systems change"),
                  tabContent: (
                    <AssessmentSystemChangeTab
                      state={state}
                      setState={setState}
                      classes={classes}
                      context={context}
                      grievanceData={grievanceData}
                      handlePreview={handlePreview}
                      showPreview={showPreview}
                    />
                  )
                },

                {
                  tabName: context.t("Next steps"),
                  tabContent: (
                    <NextStepsTab
                      state={state}
                      setState={setState}
                      classes={classes}
                      context={context}
                      onSubmit={onSubmit}
                      saving={saving}
                    />
                  )
                }
              ]}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  ) : null;
}

SupplierKpiUpdateForm.contextTypes = {
  t: PropTypes.func.isRequired
};

SupplierKpiUpdateForm.propTypes = {
  id: PropTypes.number.isRequired,
  onUpdateKPI: PropTypes.func,
  calls: PropTypes.array,
  supplierKpiCopy: PropTypes.object
};
