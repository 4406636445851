import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import loginStore from "redux/stores/LoginStore.js";
import axios from "axios";

const API_BASE_URL = `${process.env.REACT_APP_API_URL}/supplierkpis/`;

export const fetchItems = createAsyncThunk(
  "supplierKpis/fetchList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.get(API_BASE_URL, {
        params,
        headers: loginStore.getAxiosConfigHeaders().headers
      });
      const requestParams = response.config.params;

      return { data: response.data, params: requestParams };
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching data");
    }
  }
);

export const fetchSupplierKpiById = createAsyncThunk(
  "supplierKpi/fetchById",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}${id}/`, {
        params,
        headers: loginStore.getAxiosConfigHeaders().headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching details");
    }
  }
);

export const fetchSupplierKpiCalls = createAsyncThunk(
  "supplierKpi/fetchCalls",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}${id}/calls/`, {
        params,
        headers: loginStore.getAxiosConfigHeaders().headers
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error fetching calls");
    }
  }
);

export const fetchDestinationRecruiters = createAsyncThunk(
  "supplierKpi/fetchDestinationRecruiters",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}${id}/destination-recruiters/`,
        { params, headers: loginStore.getAxiosConfigHeaders().headers }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error fetching recruiters"
      );
    }
  }
);

export const fetchSourceRecruiters = createAsyncThunk(
  "supplierKpi/fetchSourceRecruiters",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}${id}/source-recruiters/`,
        { params, headers: loginStore.getAxiosConfigHeaders().headers }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Error fetching recruiters"
      );
    }
  }
);

const supplierKpiSlice = createSlice({
  name: "supplierKpi",
  initialState: {
    list: [],
    selectedById: {},
    callsById: {},
    destinationRecruitersById: {},
    sourceRecruitersById: {},
    loading: false,
    error: null,
    total: 0,
    requestParams: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchItems.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchItems.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload.data.results;
        state.total = action.payload.data.count;
        state.requestParams = action.payload.params;
      })
      .addCase(fetchItems.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSupplierKpiById.fulfilled, (state, action) => {
        console.log("I wrote into state");
        state.selectedById[action.meta.arg.id] = action.payload;
      })
      .addCase(fetchSupplierKpiCalls.fulfilled, (state, action) => {
        state.callsById[action.meta.arg.id] = action.payload;
      })
      .addCase(fetchDestinationRecruiters.fulfilled, (state, action) => {
        state.destinationRecruitersById[action.meta.arg.id] = action.payload;
      })
      .addCase(fetchSourceRecruiters.fulfilled, (state, action) => {
        state.sourceRecruitersById[action.meta.arg.id] = action.payload;
      });
  }
});

export default supplierKpiSlice.reducer;
