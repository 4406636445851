"use client";

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Paper,
  Typography,
  TextField,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  makeStyles,
  Box
} from "@material-ui/core";
import { CloudUpload as CloudUploadIcon } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  formControl: {
    margin: theme.spacing(2, 0)
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3)
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  fileInput: {
    display: "none"
  },
  uploadButton: {
    marginTop: theme.spacing(1)
  },
  fileName: {
    marginLeft: theme.spacing(1)
  }
}));

function DocumentUploader({ onAddDocument, onCancel }, context) {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    name: "",
    size: "",
    type: "SR",
    permission: "Both",
    description: ""
  });
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});

  const [unknownFileType, setUnknownFileType] = useState(false);

  const mapFileTypeToDocumentType = fileName => {
    const extension = fileName
      .split(".")
      .pop()
      .toLowerCase();

    // Document types (Reports)
    const documentExtensions = [
      "pdf",
      "doc",
      "docx",
      "txt",
      "rtf",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
      "csv"
    ];

    // Photo types
    const photoExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
      "svg",
      "webp",
      "tiff",
      "tif",
      "heic"
    ];

    // Video types
    const videoExtensions = [
      "mp4",
      "avi",
      "mov",
      "wmv",
      "flv",
      "mkv",
      "webm",
      "mpeg",
      "mpg",
      "3gp"
    ];

    if (documentExtensions.includes(extension)) {
      return "SR";
    } else if (photoExtensions.includes(extension)) {
      return "SPA";
    } else if (videoExtensions.includes(extension)) {
      return "SVA";
    } else {
      // Unknown file type
      setUnknownFileType(true);
      return "SR"; // Default to SR, but user will need to manually select
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    // Clear error when field is updated
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null
      });
    }

    if (name === "type") {
      setUnknownFileType(false);
    }
  };

  const handleFileChange = e => {
    if (e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);

      const documentType = mapFileTypeToDocumentType(selectedFile.name);
      // Auto-fill name and size
      setFormData({
        ...formData,
        name: selectedFile.name,
        size: formatFileSize(selectedFile.size),
        type: documentType,
        file: selectedFile,
        created_at: new Date().toISOString().split`T`[0]
      });

      // Clear file error if exists
      if (errors.file) {
        setErrors({
          ...errors,
          file: null
        });
      }
    }
  };

  const formatFileSize = bytes => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return (
      Number.parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i]
    );
  };

  const validate = () => {
    const newErrors = {};

    if (!file) {
      newErrors.file = "Please select a file";
    }

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!formData.permission) {
      newErrors.permission = "Permission is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (validate()) {
      onAddDocument(formData);
    }
  };

  return (
    <Paper className={classes.paper} elevation={2}>
      <Typography variant="h5" component="h2" gutterBottom>
        Upload New Document
      </Typography>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <input
              accept="*/*"
              className={classes.fileInput}
              id="file-upload"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                color="primary"
                component="span"
                startIcon={<CloudUploadIcon />}
                className={classes.uploadButton}
              >
                Select File
              </Button>
              {file && (
                <Typography
                  variant="body2"
                  component="span"
                  className={classes.fileName}
                >
                  {file.name}
                </Typography>
              )}
            </label>
            {errors.file && (
              <Typography variant="caption" color="error" display="block">
                {errors.file}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Document Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={!!errors.name}
              helperText={errors.name}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="File Size"
              name="size"
              value={formData.size}
              disabled
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Document Type</FormLabel>
              {unknownFileType && (
                <Typography
                  variant="caption"
                  className={classes.unknownTypeWarning}
                >
                  {context.t(
                    "File type not recognized. Please select the appropriate document type."
                  )}
                </Typography>
              )}
              <RadioGroup
                name="type"
                value={formData.type}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="SR"
                  control={<Radio />}
                  label="Reports"
                />
                <FormControlLabel
                  value="SPA"
                  control={<Radio />}
                  label="Photos"
                />
                <FormControlLabel
                  value="SVA"
                  control={<Radio />}
                  label="Videos"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Permission</FormLabel>
              <RadioGroup
                name="permission"
                value={formData.permission}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value="Both"
                  control={<Radio />}
                  label="Both"
                />
                <FormControlLabel
                  value="SP only"
                  control={<Radio />}
                  label="SP only"
                />
                <FormControlLabel
                  value="Supplier Only"
                  control={<Radio />}
                  label="Supplier Only"
                />
              </RadioGroup>
              {errors.permission && (
                <Typography variant="caption" color="error">
                  {errors.permission}
                </Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Note"
              name="description"
              value={formData.description}
              onChange={handleChange}
              multiline
              variant="outlined"
              placeholder="Add notes about this document..."
            />
          </Grid>
        </Grid>

        <Box className={classes.buttons}>
          <Button onClick={onCancel} className={classes.button}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Upload
          </Button>
        </Box>
      </form>
    </Paper>
  );
}

export default DocumentUploader;

DocumentUploader.propTypes = {
  onAddDocument: PropTypes.func,
  onCancel: PropTypes.func
};

DocumentUploader.contextTypes = {
  t: PropTypes.func.isRequired
};
