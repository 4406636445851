// 4 of 5. SP Priority Workplan Activities form
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./css/style1.css";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import LinearProgress from "@material-ui/core/LinearProgress";
import GridContainer from "components/Grid/GridContainer";
import IndicatorInfo from "components/Indicator/IndicatorInfo";
import CustomToggleButtonGroup from "./CustomToggleButtonGroup";
import { fetchWorkPlanData } from "redux/actions/SpWorkplanFormAction";
import axios from "axios";
import { refreshWorkPlanData } from "redux/actions/SpWorkplanFormAction";
import Utils from "services/utils";

const BottomBtnStyle = {
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "20px",
};

function SPPriorityWorkplanActivitiesForm(props, context) {
  const dispatch = useDispatch();
  const [workPlanData, setWorkPlanData] = useState({});
  const [data, setData] = useState([]);
  const [clonedData, setClonedData] = useState([]);
  // work plan item data from state
  const workPlanItem = useSelector((state) => state.workplanReducer.item);

  const [noteAndRemarkData, setNoteAndRemarkData] = useState({
    area: "workplan activity",
  });

  const [clonedNoteAndRemarkData, setClonedNoteAndRemarkData] = useState({
    area: "workplan activity",
  });

  useEffect(() => {
    !workPlanItem.loading &&
      !workPlanItem.fetched &&
      dispatch(fetchWorkPlanData());
  }, []);

  useEffect(() => {
    if (workPlanItem.fetched) {
      let workPlanData = workPlanItem.data;

      if (workPlanData && workPlanData?.priority_activity?.length > 0) {
        const flattenedDataArray = Utils.flattenSPAcitivitesDataArray(
          workPlanData.priority_activity
        );
        setData(flattenedDataArray);
        setClonedData(flattenedDataArray);
      }

      if (workPlanData && workPlanData?.id) {
        setNoteAndRemarkData({
          ...noteAndRemarkData,
          ...workPlanData.notes_remark[1],
        });
        setClonedNoteAndRemarkData({
          ...noteAndRemarkData,
          ...workPlanData.notes_remark[1],
        });
      }
      setWorkPlanData(workPlanData);
    }
  }, [workPlanItem]);

  function handleSave() {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/sp-workplan-notes_and_remarks/`,
        noteAndRemarkData
      )
      .then((response) => {
        if (response && dispatch(refreshWorkPlanData())) {
          setNoteAndRemarkData({
            ...noteAndRemarkData,
            txt_value: response?.data?.txt_value,
          });

          setClonedNoteAndRemarkData({
            ...clonedNoteAndRemarkData,
            txt_value: response?.data?.txt_value,
          });
        }
        swal({
          text: context.t(
            "Priority Workplan Activities Data saved successfully."
          ),
          icon: "success",
          buttons: false,
          timer: 2000,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const handleNext = () => {
    let dataString = JSON.stringify(data);
    let clonedDataString = JSON.stringify(clonedData);

    let noteAndRemarkDataString = JSON.stringify(noteAndRemarkData);
    let clonedNoteAndRemarkDataString = JSON.stringify(clonedNoteAndRemarkData);

    if (
      dataString == clonedDataString &&
      noteAndRemarkDataString == clonedNoteAndRemarkDataString
    ) {
      props.review();
    } else {
      swal({
        text: context.t("Changes have not been saved."),
        icon: "warning",
        buttons: {
          cancel: context.t("Cancel"),
          proceed: context.t("Proceed Anyway"),
          save: context.t("Save"),
        },
        dangerMode: true,
      }).then((willSave) => {
        switch (willSave) {
          case "proceed":
            props.review();
            break;
          case "save":
            handleSave();
            break;
        }
      });
    }
  };
  const onePointOne =
    data?.length > 0 && data.filter((item) => item.groupName === "1.1");

  const onePointTwo =
    data?.length > 0 && data.filter((item) => item.groupName === "1.2");

  const onePointThree =
    data?.length > 0 && data.filter((item) => item.groupName === "1.3");

  const onePointFour =
    data?.length > 0 && data.filter((item) => item.groupName === "1.4");

  const onePointFive =
    data?.length > 0 && data.filter((item) => item.groupName === "1.5");

  const twoPointOne =
    data?.length > 0 && data.filter((item) => item.groupName === "2.1");

  const twoPointTwo =
    data?.length > 0 && data.filter((item) => item.groupName === "2.2");

  const twoPointThree =
    data?.length > 0 && data.filter((item) => item.groupName === "2.3");

  const twoPointFour =
    data?.length > 0 && data.filter((item) => item.groupName === "2.4");

  const twoPointFive =
    data?.length > 0 && data.filter((item) => item.groupName === "2.5");

  const twoPointSix =
    data?.length > 0 && data.filter((item) => item.groupName === "2.6");

  const twoPointSeven =
    data?.length > 0 && data.filter((item) => item.groupName === "2.7");

  const twoPointEight =
    data?.length > 0 && data.filter((item) => item.groupName === "2.8");

  const twoPointTen =
    data?.length > 0 && data.filter((item) => item.groupName === "2.10");

  const threePointOne =
    data?.length > 0 && data.filter((item) => item.groupName === "3.1");

  const threePointTwo =
    data?.length > 0 && data.filter((item) => item.groupName === "3.2");

  const threePointThree =
    data?.length > 0 && data.filter((item) => item.groupName === "3.3");

  const threePointFour =
    data?.length > 0 && data.filter((item) => item.groupName === "3.4");

  const threePointFive =
    data?.length > 0 && data.filter((item) => item.groupName === "3.5");

  const threePointSix =
    data?.length > 0 && data.filter((item) => item.groupName === "3.6");

  const threePointSeven =
    data?.length > 0 && data.filter((item) => item.groupName === "3.7");

  const threePointEightA =
    data?.length > 0 && data.filter((item) => item.groupName === "3.8-a");

  const threePointEightB =
    data?.length > 0 && data.filter((item) => item.groupName === "3.8-b");

  const handleToggleChange = (id, priority_activity_id, newStatus) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, priority_status: newStatus } : item
      )
    );

    if (workPlanData && workPlanData.id) {
      const url = `${process.env.REACT_APP_API_URL}/update_activity_progress/`;
      const data = {
        priority_activity: id,
        workplan: workPlanData.id,
        priority_status: newStatus,
      };

      axios
        .post(url, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("Response:", response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <Container>
      {workPlanItem.loading ? (
        <LinearProgress />
      ) : (
        <div className="workplan-box">
          <h3>{context.t("4 of 5. Priority Workplan Activities")}</h3>
          <p>
            <strong>
              {context.t(
                "The priority activities for your company that you indicate here will appear on your SP Progress Dashboard. Please check the status boxes according to your company's status."
              )}
            </strong>
          </p>
          <br />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <IndicatorInfo />
            </Grid>
          </Grid>
          <Grid>
            <br />
          </Grid>
          <Grid container spacing={3}>
            {/* row */}
            <Grid item xs={12}>
              <h5>{context.t("ACTIVITIES FOR AREA 1 - WORKER VOICE")}</h5>
              <br />
              <p>
                <strong>
                  {context.t("Policies, Operational Systems, & Procedures")}
                </strong>
              </p>
              <p>
                <strong>
                  {context.t("1.1. Worker voice / worker validation policy")}
                </strong>
              </p>
            </Grid>
            {/* row */}
            {onePointOne &&
              onePointOne.reverse().map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}

            {/* row */}
            <Grid item xs={12}>
              <br />
            </Grid>
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>{context.t("1.2. Operational plans & rollout")}</strong>
              </p>
            </Grid>
            {/* row */}

            {onePointTwo &&
              onePointTwo.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}

            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>{context.t("Effective Communications")}</strong>
              </p>
              <p>
                <strong>
                  {context.t(
                    "1.3. Communications to workers (for Partner manufacturer/processor)"
                  )}
                </strong>
              </p>
            </Grid>
            {/* row */}
            {onePointThree &&
              onePointThree.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <br />
            </Grid>
            <Grid item xs={12}>
              <p>
                <strong>
                  {context.t(
                    "1.4. Communications between Global Buyers & Suppliers"
                  )}
                </strong>
              </p>
            </Grid>
            {/* row */}
            {onePointFour &&
              onePointFour.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>{context.t("System Strengthening")}</strong>
              </p>
              <p>
                <strong>
                  {context.t(
                    "1.5. Capacity building: Ethical Grievance Mechanisms"
                  )}
                </strong>
              </p>
            </Grid>
            {/* row */}
            {onePointFive &&
              onePointFive.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <hr />
            </Grid>
            {/* row */}
            <Grid item xs={12}>
              <h5>{context.t("ACTIVITIES FOR AREA 2 - LABOR RECRUITMENT")}</h5>
              <br />
              <p>
                <strong>
                  {context.t("Policies, Operational Systems, & Procedures")}
                </strong>
              </p>
              <p>
                <strong>
                  {context.t("2.1. Ethical recruitment policy / roadmap plan")}
                </strong>
              </p>
            </Grid>
            {/* row */}
            {twoPointOne &&
              twoPointOne.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>
                  {context.t(
                    "2.2. System and Procedures for Ethical Recruitment"
                  )}
                </strong>
              </p>
            </Grid>
            {/* row */}
            {twoPointTwo &&
              twoPointTwo.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>{context.t("Effective Communications")}</strong>
              </p>
              <p>
                <strong>
                  {context.t("2.3. Transparency in labor recruitment channels")}
                </strong>
              </p>
            </Grid>
            {/* row */}
            {twoPointThree &&
              twoPointThree.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>{context.t("2.4 Communication to suppliers")}</strong>
              </p>
            </Grid>
            {twoPointFour &&
              twoPointFour.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>
                  {context.t(
                    "2.5. Communication of Recruitment Policies to Job Candidates & Workers"
                  )}
                </strong>
              </p>
            </Grid>
            {twoPointFive &&
              twoPointFive.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>{context.t("System Strengthening")}</strong>
              </p>
              <p>
                <strong>{context.t("2.6 Recruitment surveys")}</strong>
              </p>
            </Grid>
            {twoPointSix &&
              twoPointSix.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>{context.t("2.7 Recruitment fee repayment")}</strong>
              </p>
            </Grid>
            {/* row */}
            {twoPointSeven &&
              twoPointSeven.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>
                  {context.t(
                    "2.8 Capacity building: ethical recruitment trainings"
                  )}
                </strong>
              </p>
            </Grid>
            {twoPointEight &&
              twoPointEight.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>{context.t("2.10 Golden Dreams")}</strong>
              </p>
            </Grid>
            {twoPointTen &&
              twoPointTen.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid item xs={12}>
              <h5>{context.t("ACTIVITIES FOR AREA 3 - WORKING CONDITIONS")}</h5>
              <br />
              <p>
                <strong>
                  {context.t("Policies, Operational Systems, & Procedures")}
                </strong>
              </p>
            </Grid>
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>
                  {context.t("3.1. Establish sourcing policy / roadmap plan")}
                </strong>
              </p>
            </Grid>
            {threePointOne &&
              threePointOne.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>
                  {context.t("3.5. Independent worker satisfaction surveys")}
                </strong>
              </p>
            </Grid>
            {threePointFive &&
              threePointFive.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>{context.t("Effective Communications")}</strong>
              </p>
              <p>
                <strong>{context.t("3.3 Communication to suppliers")}</strong>
              </p>
            </Grid>
            {threePointThree &&
              threePointThree.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>{context.t("3.4 Communication to workers")}</strong>
              </p>
            </Grid>
            {threePointFour &&
              threePointFour.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>{context.t("System Strengthening")}</strong>
              </p>
              <p>
                <strong>
                  {context.t("3.2. Ongoing worker-driven remediation")}
                </strong>
              </p>
            </Grid>
            {threePointTwo &&
              threePointTwo.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>{context.t("3.6. Capacity building: HRDD")}</strong>
              </p>
            </Grid>
            {threePointSix &&
              threePointSix.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>
                  {context.t(
                    "3.7. Capacity building: interpreters & line supervisors"
                  )}
                </strong>
              </p>
            </Grid>
            {threePointSeven &&
              threePointSeven.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>
                  {context.t("3.8. Capacity building: management & leadership")}
                </strong>
              </p>
            </Grid>
            {/* row */}
            {threePointEightA &&
              threePointEightA.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <p>
                <strong>
                  {context.t("3.8. Capacity building: management & leadership")}
                </strong>
              </p>
            </Grid>
            {threePointEightB &&
              threePointEightB.map((item) => (
                <Grid
                  container
                  spacing={3}
                  key={item.id}
                  style={{ marginBottom: "16px" }}
                >
                  <Grid item xs={7} md={6}>
                    <Box ml={7}>
                      <p>{item.description}</p>
                    </Box>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <CustomToggleButtonGroup
                      id={item.id.toString()}
                      name={item.description}
                      value={item.priority_status || "not yet"}
                      onChange={(newStatus) =>
                        handleToggleChange(
                          item.id,
                          item.priority_activity_id,
                          newStatus
                        )
                      }
                      complete_color_teal={true}
                    />
                  </Grid>
                </Grid>
              ))}
            {/* row */}
            <Grid item xs={12}>
              <hr />
            </Grid>
            {/* row */}
          </Grid>
          <Grid>
            <h5>
              {context.t(
                "NOTES & REMARKS ON THE PRIORITY WORKPLAN ACTIVITIES FOR THE ANNUAL WORKPLAN"
              )}
            </h5>
            <p>
              {context.t(
                "Please feel free to add any note in this space, either for yourself or to note for the Issara team."
              )}
            </p>
            <TextareaAutosize
              id="free-note"
              style={{
                width: "100%",
                border: "1px solid black",
                padding: "10px",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}
              aria-label="minimum height"
              minRows={10}
              value={noteAndRemarkData && noteAndRemarkData.txt_value}
              onChange={(e) => {
                let workplan = workPlanData?.id;
                setNoteAndRemarkData({
                  ...noteAndRemarkData,
                  txt_value: e.target.value,
                  ...(workplan && { workplan: workplan }),
                });
              }}
            />
            <GridContainer style={{ marginTop: "10px" }}>
              <Grid item xs={6} sm={6} md={8} lg={8}>
                <Button
                  onClick={props.back}
                  variant="contained"
                  width="200px"
                  style={{ marginLeft: "5px" }}
                >
                  {context.t("Back")}
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} style={BottomBtnStyle}>
                <Button
                  onClick={handleSave}
                  variant="contained"
                  width="200px"
                  style={{ marginRight: "15px", backgroundColor: "#03A9F4" }}
                >
                  {context.t("Save")}
                </Button>
                <Button
                  onClick={handleNext}
                  variant="contained"
                  width="200px"
                  style={{ marginRight: "15px" }}
                >
                  {context.t("Review Workplan")}
                </Button>
              </Grid>
            </GridContainer>
          </Grid>
        </div>
      )}
    </Container>
  );
}

export default SPPriorityWorkplanActivitiesForm;

SPPriorityWorkplanActivitiesForm.contextTypes = {
  t: PropTypes.func.isRequired,
};
