import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Modal from "@material-ui/core/Modal";
import Divider from "@material-ui/core/Divider";
import Button from "components/CustomButtons/Button.js";
import CloseIcon from "@material-ui/icons/Close";
import SweetAlert from "react-bootstrap-sweetalert";

import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import {
  fetchSupplierKpiById,
  fetchSupplierKpiCalls,
  fetchDestinationRecruiters,
  fetchSourceRecruiters
} from "redux/stores/supplierKpi/supplierKpiSlice";
import { fetchItems as fetchSupplierKpiUpdates } from "redux/stores/supplierKpi/supplierKpiUpdateSlice";

import SupplierKpiDetailsSubPanel from "./SupplierKpiDetailsSubPanel.js";

import { batchRemoveSupplierKpis } from "redux/actions/SupplierActions";

const customStyles = {
  ...customCheckboxRadioSwitch,
  ...sweetAlertStyles,

  paper: {
    backgroundColor: "white",
    padding: 30,
    webkitBoxShadow: "5px 6px 10px 0px rgba(0,0,0,0.27)",
    mozBoxShadow: "5px 6px 10px 0px rgba(0,0,0,0.27)",
    boxShadow: "5px 6px 10px 0px rgba(0,0,0,0.27)"
  },
  preBlock: {
    whiteSpace: "pre-wrap",
    wordBreak: "keep-all",
    scroll: "none",
    width: "100%"
  },
  modal: {
    display: "block",
    maxWidth: "70%",
    height: "100%",
    overflowWrap: "break-word",
    overflowY: "auto;",
    overflowX: "none;",
    paddingBottom: "20px"
  },
  spanLabel: {
    fontWeight: 500
  },
  customButton: {
    position: "absolute",
    padding: 0,
    margin: 0,
    top: "40px",
    right: "40px",
    scrollbars: "none"
  },
  updateContainer: {
    display: "inline-block",
    margin: "10px",
    textAlign: "center"
  },
  updateCircle: {
    border: "2px dotted",
    borderRadius: "100%",
    margin: "auto",
    width: 40,
    height: 40
  },
  updateCircleArrow: {
    position: "absolute",
    fontSize: "20px",
    fontWeight: 500,
    top: "35px",
    marginLeft: "35px"
  },
  updateCircleCurrent: {
    color: "green",
    position: "absolute",
    marginLeft: "-4px",
    marginTop: "-20px"
  },
  updateHeaderSpan: {
    display: "inline",
    marginRight: "10px",
    fontWeight: 400
  }
};

const useStyles = makeStyles(customStyles);

export default function SupplierKpiViewInstance(props, context) {
  const [isLoading, setIsLoading] = useState(true);
  const params = { lang: "en" };
  const dispatch = useDispatch();
  const classes = useStyles();
  const supplierKpiId = props.value;

  const { selected: kpi, calls } = useSelector(state => ({
    selected: state.supplierKpiReducer.selectedById[supplierKpiId],
    calls: state.supplierKpiReducer.callsById[supplierKpiId] || []
  }));

  const { requestParams: cachedRequestParams } = useSelector(state => ({
    requestParams: state.supplierKpiUpdateReducer.requestParams
  }));

  useEffect(() => {
    if (props.open && supplierKpiId) {
      setIsLoading(true);

      Promise.all([
        dispatch(fetchSupplierKpiById({ id: supplierKpiId, params })),
        dispatch(fetchSupplierKpiCalls({ id: supplierKpiId, params })),
        dispatch(fetchDestinationRecruiters({ id: supplierKpiId, params })),
        dispatch(fetchSourceRecruiters({ id: supplierKpiId, params })),
        dispatch(
          fetchSupplierKpiUpdates({
            supplier_kpi: supplierKpiId
          })
        )
      ]).finally(() => {
        setIsLoading(false);
      });
    }
  }, [props.open, supplierKpiId, supplierKpiId]);
  const [alertBox, setAlertBox] = useState(null);

  const showDeleteConfirmation = () => {
    setAlertBox(
      <SweetAlert
        success
        showConfirm
        showCancel
        onConfirm={() => {
          dispatch(
            batchRemoveSupplierKpis(
              [supplierKpiId],
              () => {
                setTimeout(() => {
                  setAlertBox(
                    <SweetAlert
                      success
                      title={context.t("Supplier KPI Deleted")}
                      onConfirm={() => {
                        setAlertBox(null);
                        props.onClose();
                      }}
                      confirmBtnCssClass={classes.button + " " + classes.info}
                    >
                      {context.t(
                        "You have successfully deleted the supplier kpi and it's child updates"
                      )}
                    </SweetAlert>
                  );
                }, -1);
              },
              () => {
                setAlertBox(
                  <SweetAlert
                    error
                    title={context.t("Error Supplier KPI was not deleted")}
                    onConfirm={() => {
                      setAlertBox(null);
                    }}
                    confirmBtnCssClass={classes.button + " " + classes.info}
                  >
                    {context.t(
                      "Error occurred trying to delete those supplier kpi updates"
                    )}
                  </SweetAlert>
                );
              }
            )
          );
        }}
        onCancel={() => setAlertBox(null)}
        confirmBtnCssClass={classes.button + " " + classes.info}
        cancelBtnCssClass={classes.button + " " + classes.default}
        confirmBtnText={context.t("Confirm")}
        title={context.t("Confirm Delete ?")}
      >
        {context.t("About to delete supplier kpi id: ") + supplierKpiId}
      </SweetAlert>
    );
  };

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      className={classes.modal + " modal"}
    >
      {isLoading || !kpi ? (
        <div>Loading...</div>
      ) : (
        <div className={classes.paper}>
          {alertBox}

          {calls && calls.length < 1 ? (
            <div>
              <h4>
                {context.t(
                  "*This Supplier KPI has 0 cases and is ok to remove."
                )}
              </h4>
              <Button
                color="rose"
                onClick={() => {
                  showDeleteConfirmation();
                }}
              >
                {context.t("Delete")}
              </Button>
            </div>
          ) : null}

          <h3 style={{ marginTop: 0 }}>
            {context.t("Supplier KPI #{id}", { id: supplierKpiId })}
          </h3>
          <Button
            simple
            color="danger"
            className={classes.customButton}
            onClick={() => props.onClose()}
          >
            <CloseIcon />
          </Button>
          <Divider />
          <h5> {context.t("Overview:")} </h5>

          <p>
            <span className={classes.spanLabel}>
              {context.t("Update Source:")}
            </span>
            {kpi &&
            (kpi.kpi_update_source !== null &&
              kpi.kpi_update_source !== undefined &&
              kpi.kpi_update_source !== "")
              ? kpi.kpi_update_source
              : "N/A"}
          </p>
          <p>
            <span className={classes.spanLabel}>{context.t("Supplier:")}</span>
            {kpi?.supplier_name}
          </p>
          <p>
            <span className={classes.spanLabel}>{context.t("Category:")}</span>
            {kpi?.category || "-"}
          </p>
          <p className={classes.preBlock}>
            <span className={classes.spanLabel}>
              {context.t("Description:")}
            </span>
            {kpi?.description || "-"}
          </p>
          <p>
            <span className={classes.spanLabel}>{context.t("Level:")}</span>
            {kpi?.level || "-"}
          </p>
          <p>
            <span className={classes.spanLabel}>
              {context.t("Laws Violated:")}
            </span>
            {kpi?.law || "-"}
          </p>
          <p>
            <span className={classes.spanLabel}>
              {context.t("# Workers Impacted:")}
            </span>
            {kpi?.affected_workers || "-"}
          </p>
          <p>
            <span className={classes.spanLabel}>{context.t("Status:")}</span>
            {kpi.status_name}
          </p>
          <p>
            <span className={classes.spanLabel}>
              {context.t("Days Active:")}
            </span>
            {kpi?.days_open || "-"}
          </p>
          <p>
            <span className={classes.spanLabel}>
              {context.t("Open Since:")}
            </span>
            {kpi?.opened_at || "-"}
          </p>
          <p>
            <span className={classes.spanLabel}>{context.t("Updated:")}</span>
            {kpi?.overview_date || "-"}
          </p>

          {kpi?.closed_at && (
            <div>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("Closed:")}
                </span>
                {(kpi?.closed_at && kpi.closed_at) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("Closed Quality:")}
                </span>
                {kpi?.closed_quality || "-"}
              </p>
              <div>
                <span className={classes.spanLabel}>
                  {context.t("Rationale:")}
                </span>
                <p
                  className={classes.preBlock}
                  style={{ overflow: "overflow-wrap" }}
                >
                  {kpi?.closed_notes || "-"}
                </p>
              </div>
            </div>
          )}
          <Divider />
          <h5> {context.t("Remediation:")} </h5>
          <div>
            <span className={classes.spanLabel}>
              {context.t("Issara Recommendations:")}
            </span>
            <p
              className={classes.preBlock}
              style={{ overflow: "overflow-wrap" }}
            >
              {kpi?.remediation_issara_recommendation || "-"}
            </p>
          </div>
          <p className={classes.preBlock}>
            <span className={classes.spanLabel}>
              {context.t("Progress on remediation:")}
            </span>
            {kpi?.remediation_progress || "-"}
          </p>
          <p>
            <span className={classes.spanLabel}>
              {context.t("How aligned are the business's responses:")}
            </span>
            {kpi?.remediation_aligned || "-"}
          </p>
          <div>
            <span className={classes.spanLabel}>
              {context.t("Business Steps Taken:")}
            </span>
            <p
              className={classes.preBlock}
              style={{ overflow: "overflow-wrap" }}
            >
              {kpi?.remediation_business_steps_taken || "-"}
            </p>
          </div>
          <div>
            <span className={classes.spanLabel}>
              {context.t("Business Steps Remaining:")}
            </span>
            <p
              className={classes.preBlock}
              style={{ overflow: "overflow-wrap" }}
            >
              {kpi?.remediation_business_steps_remaining || "-"}
            </p>
          </div>
          <div>
            <span className={classes.spanLabel}>
              {context.t("Notes (Talking Points):")}
            </span>
            <p
              className={classes.preBlock}
              style={{ overflow: "overflow-wrap" }}
            >
              {kpi?.remediation_notes || "-"}
            </p>
          </div>
          <div>
            <span className={classes.spanLabel}>
              {context.t("Action (Talking Points):")}
            </span>
            <p
              className={classes.preBlock}
              style={{ overflow: "overflow-wrap" }}
            >
              {kpi?.remediation_action || "-"}
            </p>
          </div>
          <div>
            <span className={classes.spanLabel}>
              {context.t("Validation (Talking Points):")}
            </span>
            <p
              className={classes.preBlock}
              style={{ overflow: "overflow-wrap" }}
            >
              {kpi?.remediation_validation || "-"}
            </p>
          </div>
          <div>
            <span className={classes.spanLabel}>
              {context.t("Results (Talking Points):")}
            </span>
            <p
              className={classes.preBlock}
              style={{ overflow: "overflow-wrap" }}
            >
              {kpi?.remediation_results || "-"}
            </p>
          </div>

          <Divider />
          <h5> {context.t("Payments:")} </h5>
          <p>
            <span className={classes.spanLabel}>{context.t("Owed:")}</span>
            {`${kpi?.remediation_owed_baht} Baht, ${kpi?.remediation_owed_kyat} Kyat, ${kpi?.remediation_owed_ringitt} Ringitt, ${kpi?.remediation_owed_usd} USD`}
          </p>
          <p>
            <span className={classes.spanLabel}>{context.t("Paid:")}</span>
            {`${kpi?.remediation_paid_baht} Baht, ${kpi?.remediation_paid_kyat} Kyat, ${kpi?.remediation_paid_ringitt} Ringitt, ${kpi?.remediation_paid_usd} USD`}
          </p>
          <p>
            <span className={classes.spanLabel}>
              {context.t("Payment Deadline:")}
            </span>
            {kpi?.remediation_payment_deadline || "-"}
          </p>
          <Divider />
          <h5>{context.t("Documents:")}</h5>
          <p>
            <span className={classes.spanLabel}>{context.t("Owed:")}</span>
            {kpi?.remediation_documents_owed || "-"}
          </p>
          <p>
            <span className={classes.spanLabel}>{context.t("Provided:")}</span>
            {kpi?.remediation_documents_provided || "-"}
          </p>
          <p>
            <span className={classes.spanLabel}>
              {context.t("Documents Deadline:")}
            </span>
            {kpi?.remediation_documents_deadline || "-"}
          </p>
          <Divider />
          <h5> {context.t("Systems Change:")} </h5>
          <div>
            <span className={classes.spanLabel}>
              {context.t("Issara Recommendations:")}
            </span>
            <p
              className={classes.preBlock}
              style={{ overflow: "overflow-wrap" }}
            >
              {kpi?.systems_change_issara_recommendation || "-"}
            </p>
          </div>
          <p>
            <span className={classes.spanLabel}>
              {context.t("Progress on remediation:")}
            </span>
            {kpi?.systems_change_progress || "-"}
          </p>
          <p>
            <span className={classes.spanLabel}>
              {context.t("How aligned are the business's responses:")}
            </span>
            {kpi?.systems_change_aligned || "-"}
          </p>
          <div>
            <span className={classes.spanLabel}>
              {context.t("Business Steps Taken:")}
            </span>
            <p
              className={classes.preBlock}
              style={{ overflow: "overflow-wrap" }}
            >
              {kpi?.systems_change_business_steps_taken || "-"}
            </p>
          </div>
          <div>
            <span className={classes.spanLabel}>
              {context.t("Business Steps Remaining:")}
            </span>
            <p
              className={classes.preBlock}
              style={{ overflow: "overflow-wrap" }}
            >
              {kpi?.systems_change_business_steps_remaining || "-"}
            </p>
          </div>
          <p>
            <span className={classes.spanLabel}>
              {context.t("System Change Deadline:")}
            </span>
            {kpi?.systems_change_deadline || "-"}
          </p>

          <Divider />
          <h5>{context.t("Next Step :")}</h5>
          <p>
            <span className={classes.spanLabel}>
              {context.t("Assign To :-")}
            </span>
            {kpi && kpi.assigned_to !== null ? kpi.assigned_to.username : "-"}
          </p>
          <p>
            <span className={classes.spanLabel}>
              {context.t("Deadline :-")}
            </span>
            {kpi?.deadline || "-"}
          </p>
          <p>
            <span className={classes.spanLabel}>
              {context.t("Description :-")}
            </span>
            {kpi?.next_description || "-"}
          </p>
          <Divider />

          <GridContainer>
            <div>
              <br />
            </div>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} lg={12}>
              {kpi !== undefined && kpi !== null ? (
                <SupplierKpiDetailsSubPanel
                  showDataCorrectionUI={true}
                  supplierKpiId={kpi.id}
                />
              ) : null}
            </GridItem>
          </GridContainer>
        </div>
      )}
    </Modal>
  );
}

SupplierKpiViewInstance.contextTypes = {
  t: PropTypes.func.isRequired
};

SupplierKpiViewInstance.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired
};
