import dispatcher from "../dispatcher";
import HttpService from "../../services/HttpService";
import swal from "sweetalert";

function showErrorAlert(message) {
  swal("Error", message, "error");
}

export function dispatchLoginResponse(resp, loginForm) {
  console.log("dispatchLoginResponse", resp);
  dispatcher.dispatch({
    type: "LOGIN_EVENT",
    loginUser: resp,
    loginForm: loginForm,
  });
}

export function login(loginForm) {
  HttpService.post(
    process.env.REACT_APP_API_BASE_URL + "/two_factor_auth/get-code/",
    loginForm,
    (resp) => {
      dispatchLoginResponse(resp, loginForm);
    },
    (err) => {
      console.log(err.message);
      if (err.message === "Request failed with status code 400") {
        showErrorAlert("Invalid login!");
      } else if (err.message === "Network Error") {
        showErrorAlert(err.message);
      }
    }
  );
}

export function changePassword(
  id,
  old_password,
  password,
  password2,
  successHandler,
  errorHandler
) {
  HttpService.put(
    process.env.REACT_APP_API_BASE_URL +
      "/api-auth-user/change_password/" +
      id +
      "/",
    {
      old_password,
      password,
      password2,
    },
    (resp) => {
      successHandler(resp);
    },
    (err) => {
      errorHandler(err);
      showErrorAlert(err.message);
    }
  );
}

export function resetPassword(
  token,
  password,
  password2,
  successHandler,
  errorHandler
) {
  if (
    password === null ||
    password === undefined ||
    password === "" ||
    password !== password2
  ) {
    const errorMessage =
      "Password must not be empty and the new passwords must be the same in both form fields.";
    errorHandler({ message: errorMessage });
    showErrorAlert(errorMessage);
    return;
  } else if (token === null || token === undefined || token === "") {
    const errorMessage =
      "Token is invalid. Please make sure you got to this page through the valid link on your account's email.";
    errorHandler({ message: errorMessage });
    showErrorAlert(errorMessage);
    return;
  }

  const payload = { password: password, token: token };

  HttpService.post(
    process.env.REACT_APP_API_BASE_URL +
      "/api-auth-user/password_reset/confirm/",
    payload,
    (resp) => {
      successHandler(resp);
    },
    (err) => {
      errorHandler(err);
      showErrorAlert(err.message);
    }
  );
}

export function requestResetPassword(email, successHandler, errorHandler) {
  if (email === null || email === undefined || email === "") {
    const errorMessage = "Please make sure email is a valid email";
    errorHandler({ message: errorMessage });
    showErrorAlert(errorMessage);
    return;
  }

  const payload = { email: email };

  HttpService.post(
    process.env.REACT_APP_API_BASE_URL + "/api-auth-user/password_reset/",
    payload,
    (resp) => {
      successHandler(resp);
    },
    (err) => {
      errorHandler(err);
      showErrorAlert(err.message);
    }
  );
}

export function logout() {
  console.log("LoginActions.logout");
  dispatcher.dispatch({ type: "LOGOUT_EVENT" });
}
